import { useActifDisconnection } from '../../store/actif/hook';
import { useOrderDisconnection } from '../../store/order/hook';
import { useSquadDisconnection } from '../../store/squad/hook';
import { useDisconnection } from '../../store/user/hook';

function useDisconnectionStore() {
	return {
		disconnectionUser: useDisconnection(),
		disconnectionSquad: useSquadDisconnection(),
		disconnectionOrder: useOrderDisconnection(),
		disconnectionActif: useActifDisconnection(),
	};
}

export default useDisconnectionStore;
