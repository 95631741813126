import React from 'react';
import './index.css';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { Link } from 'react-router-dom';
const { ReactComponent: Warren } = require("../../../assets/warrenSvg.svg");

const tabTheme = ['buffet', 'ackman', 'burry', 'wood'];

const backgroundTheme = [
	'linear-gradient(199.85deg, #44599C 1.33%, #131938 75%)',
	'linear-gradient(199.85deg, #44599C 1.33%, #131938 75%)',
	'linear-gradient(199.85deg, #44599C 1.33%, #131938 75%)',
	'linear-gradient(199.85deg, #44599C 1.33%, #131938 75%)',
];

const images = [
	require('../../../assets/warren.png'),
	require('../../../assets/ackman.png'),
	require('../../../assets/burry.png'),
	require('../../../assets/wood.png'),
];
const IdeeInvest = () => {
	const queryParameters = new URLSearchParams(window.location.search);
	const theme = queryParameters.get('');
	if (!theme) return <></>;
	const realTheme = tabTheme.indexOf(theme);
	if (realTheme < 0) return <></>;
	return (
		<div id='ideeInvest'>
			<div id='chevronsIdee'>
				<Link
					to='/app/idee'
					style={{
						height: '2rem',
						width: '2rem',
						background: 'var(--color--SecondBackground)',
						textDecoration: 'none',
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '100px',
						color: 'var(--color--Blanc)',
					}}
					className='hgp'
				>
					<ChevronLeftRoundedIcon style={{ width: '2rem', height: '2rem' }} />
				</Link>
				<div
					style={{
						height: '2rem',
						width: '2rem',
						background: 'var(--color--SecondBackground)',
						textDecoration: 'none',
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '100px',
						marginLeft: '1rem',
						color: 'var(--color--GrisText)',
					}}
				// className='hgp'
				>
					<ChevronRightRoundedIcon style={{ width: '2rem', height: '2rem' }} />
				</div>
			</div>
			<div id='ideeInvestBody' style={{ background: backgroundTheme[realTheme] }}>
				{/* <img src={images[realTheme]} style={{ height: '170px', width: '170px' }} alt='idee' /> */}
				<Warren />
			</div>
		</div>
	);
};

export default IdeeInvest;
