import {
	Avatar,
	Box,
	Button,
	Dialog,
	DialogTitle,
	InputLabel,
	Paper,
	Popper,
	TextField,
	Tooltip,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SignUpSquad, { parrainageState } from '../SignUpSquad';
import { squadType } from '../../../store/squad/reducer';
import { AddRounded } from '@mui/icons-material';
import { userState } from '../../../store/user/reducer';
import { initOpenVote } from '..';
import { addPopup } from '../../../store/popup/reducer';

export type openVoteType = {
	openInvite: boolean;
	openVote: boolean;
	openProfil: boolean;
	openLeave: boolean;
};

const ajouteParrainageSiComplet = (parrainage: parrainageState, setParrainage: any) => {
	if (
		parrainage.current.email === '' ||
		parrainage.current.message === '' ||
		parrainage.squadName === ''
	)
		return;
	const newTab = [...parrainage.tab];
	newTab.push(parrainage.current);
	setParrainage({
		squadName: parrainage.squadName,
		tab: newTab,
		current: { email: '', message: '' },
	});
};

function PopUpSignSquad(
	openVote: openVoteType,
	setOpenVote: any,
	parrainage: parrainageState,
	setParrainage: any,
	currentSquad: squadType | null,
	user: userState,
	connectionUser: any,
	connectionSquad: any,
	connectionOrder: any,
	connectionActif: any,
	addPopup: any,
) {
	return (
		<Dialog
			open={openVote.openInvite}
			onClose={() => setOpenVote(initOpenVote)}
			PaperProps={{
				style: {
					height: '100%',
					width: '100%',
					position: 'relative',
					margin: 0,
					padding: '2rem',
					// background: 'var(--color--Background)',
				},
			}}
		>
			<CloseRoundedIcon
				style={{
					alignSelf: 'flex-end',
					border: '1px solid white',
					borderRadius: '8px',
					height: '1rem',
					width: '1rem',
					cursor: 'pointer',
					padding: '0.1rem',
				}}
				onClick={() => setOpenVote({ ...initOpenVote, openInvite: !openVote.openInvite })}
			/>
			<DialogTitle>
				Trouve des potes 🫵{' '}
				<div className='sousTitre'>pour constituer ton Squad et te donner des idées !</div>
			</DialogTitle>
			<Box
				sx={{
					display: currentSquad ? 'none' : 'flex',
					alignItems: 'flex-start',
					color: 'white',
					width: '100%',
					flexDirection: 'column',
				}}
			>
				<InputLabel>Nom du Squad</InputLabel>
				<TextField
					InputLabelProps={{ color: 'info' }}
					InputProps={{ color: 'info', className: 'testBorderBlanc ' }}
					value={parrainage.squadName}
					onChange={(value) => {
						if (value.target.value.length < 11)
							setParrainage({ ...parrainage, squadName: value.target.value });
					}}
					color='info'
				/>
			</Box>
			<Box
				sx={{
					width: '100%',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Paper sx={{ marginTop: currentSquad ? '0' : '' }} id='hpg'>
					Pour chaque membre invité ton Squad reçoit $ 1500 🤑
				</Paper>
				{/* <Paper sx={{ width: '100%' }}>Adress e-mail:</Paper> */}
				<Box
					sx={{
						display: 'flex',
						alignItems: 'flex-start',
						color: 'white',
						width: '100%',
						flexDirection: 'column',
						marginTop: '0.5rem',
					}}
				>
					<InputLabel>Saisis son e-mail</InputLabel>
					<TextField
						// label='Saisissez son adresse e-mail'
						InputLabelProps={{ color: 'info' }}
						InputProps={{ color: 'info', className: 'testBorderBlanc ' }}
						value={parrainage.current.email}
						onChange={(value) =>
							setParrainage({
								...parrainage,
								current: { ...parrainage.current, email: value.target.value },
							})
						}
						color='info'
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'flex-start',
						color: 'white',
						width: '100%',
						flexDirection: 'column',
						marginTop: '0.5rem',
					}}
				>
					<InputLabel>Laisse lui un message </InputLabel>
					<TextField
						InputProps={{ className: 'testBorderBlanc' }}
						value={parrainage.current.message}
						onChange={(value) =>
							setParrainage({
								...parrainage,
								current: { ...parrainage.current, message: value.target.value },
							})
						}
						color='info'
					/>
				</Box>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						fontWeight: '400',
						fontSize: '0.8rem',
						alignSelf: 'flex-start',
					}}
					className='hgp2'
					onClick={() => ajouteParrainageSiComplet(parrainage, setParrainage)}
				>
					<AddRounded
						style={{ height: '1.5rem', width: '1.5rem' }}
						className='nextPerson'
						color={
							parrainage.current.email === '' ||
							parrainage.current.message === '' ||
							parrainage.squadName === ''
								? 'info'
								: 'success'
						}
					/>
					<strong>Ajouter un membre</strong>
				</div>
				<Button
					disabled={
						(parrainage.current.email === '' ||
							parrainage.current.message === '' ||
							parrainage.squadName === '') &&
						!parrainage.tab.length
					}
					onClick={() => {
						SignUpSquad(
							parrainage,
							currentSquad,
							user,
							connectionUser,
							connectionSquad,
							connectionOrder,
							connectionActif,
							addPopup,
						);
						setOpenVote(initOpenVote);
					}}
					style={{ marginTop: '1rem' }}
				>
					Commencer (
					{parrainage.tab.length +
						(parrainage.current.email === '' || parrainage.current.message === '' ? 0 : 1)}
					)
				</Button>
			</Box>
		</Dialog>
	);
}

export default PopUpSignSquad;
