import { initOpenVote } from '..';
import { openVoteType } from '../PopUpSignSquad';
import { parrainageState } from '../SignUpSquad';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import './index.css';
import { Box, Button, Dialog, DialogTitle } from '@mui/material';
import $axios from '../../../utils/axios';
import { useUser } from '../../../store/user/hook';
import { useSquadDisconnection } from '../../../store/squad/hook';
import useDisconnectionStore from '../../../utils/disconnectStore/disconnection';

export interface PopUpLeaveProps {
	openVote: openVoteType;
	setOpenVote: any;
	parrainage: parrainageState;
	setParrainage: any;
}

const PopUpLeave = (props: PopUpLeaveProps) => {
	const { openVote, setOpenVote, parrainage, setParrainage } = props;
	const { disconnectionUser, disconnectionSquad, disconnectionOrder, disconnectionActif } =
		useDisconnectionStore();
	const leaveSquad = () => {
		$axios.post('squad/signout').then((res) => {
			console.log(res.data);
			disconnectionSquad();
			disconnectionOrder();
			disconnectionActif();
		});
	};

	return (
		<Dialog
			open={openVote.openLeave}
			onClose={() => setOpenVote(initOpenVote)}
			sx={{
				background: 'transparent',
			}}
			PaperProps={{
				style: {
					height: '100%',
					width: '100%',
					position: 'relative',
					margin: 0,
					padding: '2rem',
					// background: 'var(--color--Background)',
				},
			}}
		>
			<CloseRoundedIcon
				style={{
					alignSelf: 'flex-end',
					border: '1px solid white',
					borderRadius: '8px',
					height: '1rem',
					width: '1rem',
					cursor: 'pointer',
					padding: '0.1rem',
				}}
				onClick={() => setOpenVote({ ...initOpenVote, openLeave: !openVote.openLeave })}
			/>
			<DialogTitle>
				Quitter {parrainage.squadName} ?
				<div className='sousTitre'>Tu perds tout !</div>
			</DialogTitle>
			<Box
				sx={{
					width: '100%',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Button
					onClick={() => {
						leaveSquad();
						setOpenVote(initOpenVote);
					}}
					style={{ marginTop: '1rem' }}
				>
					Confirmer
				</Button>
			</Box>
		</Dialog>
	);
}

export default PopUpLeave;