import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AppState, useAppSelector } from '..';
import { voteType } from '../../components/Dashboard/PopUpVote';
import { useActifPriceMap } from '../actif/hook';
import { actifState, priceType } from '../actif/reducer';
import { userState } from '../user/reducer';
import { connection, disconnection, orderState, orderType } from './reducer';

export function useOrderConnection(): (
	tabOrders: orderType[],
	squadName: string,
	percentage: number,
) => void {
	const dispatch = useDispatch();

	return useCallback(
		(tabOrders: orderType[], squadName: string, percentage: number) => {
			dispatch(connection({ squadName, tabOrders, percentage }));
		},
		[dispatch],
	);
}

export function useOrderDisconnection(): () => void {
	const dispatch = useDispatch();

	return useCallback(() => {
		dispatch(disconnection());
	}, [dispatch]);
}

export function useOrder(): AppState['order'] {
	return useAppSelector((state: AppState) => state.order);
}

// valide
// negatif ou positif selon vente ou achat
function actionQuantityValue(order: orderType) {
	if (order.actif === 'usd')
		return order.actionType === 2 ? order.actionQuantity : -order.actionQuantity;
	return order.actionType ? order.actionQuantity : -order.actionQuantity;
}
// valide
// valeur selon vente ou achat
function realPriceValue(order: orderType, mapActifPrice: Map<string, priceType>): number {
	if (order.actif === 'usd') return 1;
	if (!order.actionType || order.realPrice === undefined) return 0;
	if (order.actionType && !mapActifPrice.has(order.actif)) return 0;
	return order.actionType ? mapActifPrice.get(order.actif)!.price : order.realPrice;
}

// valide
// cash depense ou gagne lors de lachat et la vente
function realCashValue(order: orderType) {
	if (order.actif === 'usd') return actionQuantityValue(order);
	//negatif lorsque jachete et positif lorsque je vend
	if (order.realPrice === undefined) return 0;
	const realPrice = order.actionType ? -order.realPrice : order.realPrice;
	return order.actionQuantity * realPrice;
}

// valide
// calcul la quantite de l'actif que possede le squad
export function useOrderedActif(actifName: string): number {
	return useAppSelector((state: AppState) => {
		let quantity = 0;
		state.order.orders.forEach((order) => {
			if (order.actif === actifName) {
				const actualValue = actionQuantityValue(order);
				quantity += actualValue;
			}
		});
		return quantity;
	});
}

// valide
// calcul le cash du squad restant
export function useOrderCash(order: orderState): number {
	let quantity = 0;
	order.orders.forEach((order) => {
		if (order.state !== 2) return;
		const actualValue = realCashValue(order);
		quantity += actualValue;
	});
	return quantity;
}

//valide
// calcul le montant qu'a deposer l'utilisateur (reduit le montant a chaque retrait)
export function useOrderInvested(orderS: orderState, userId: number): number {
	let quantity = 0;
	orderS.orders.forEach((order) => {
		if (order.actif === 'usd' && order.userId === userId) {
			const actualValue = realCashValue(order);
			quantity += actualValue;
		}
	});
	return quantity;
}

export type gainType = {
	actifName: string;
	restant: number;
	meanPrice: number;
	gain: number;
};

export function useMapActifGain(orderS: orderState) {
	const mapActifGain = new Map<string, gainType>();

	orderS.orders.forEach((order) => {
		if (order.state !== 2) return;
		if (order.actif === 'usd') {
			// le depot de dollar ne change en rien le profit genere par le squad
			// quantity += actionQuantityValue(order);
			return;
		}
		// achat
		if (order.realPrice === undefined)
			return console.error('Order finished without price! id: ', order.id);
		if (order.actionType) {
			if (!mapActifGain.has(order.actif)) {
				mapActifGain.set(order.actif, {
					restant: order.actionQuantity,
					meanPrice: order.realPrice,
					gain: 0,
					actifName: order.actif,
				});
				return;
			}
			const oldGain = mapActifGain.get(order.actif)!;
			const newRestant = oldGain.restant + order.actionQuantity;
			const newMean =
				(oldGain.meanPrice * oldGain.restant + order.actionQuantity * order.realPrice) /
				newRestant;
			mapActifGain.set(order.actif, {
				restant: newRestant,
				meanPrice: newMean,
				gain: oldGain.gain,
				actifName: order.actif,
			});
			return;
		}
		//vente
		if (!mapActifGain.has(order.actif))
			return console.error('Order selling something not bought! id: ', order.id);
		const oldGain = mapActifGain.get(order.actif)!;
		const newRestant = oldGain.restant - order.actionQuantity;
		const newGain =
			oldGain.gain +
			order.actionQuantity * order.realPrice -
			order.actionQuantity * oldGain.meanPrice;
		mapActifGain.set(order.actif, {
			restant: newRestant,
			meanPrice: oldGain.meanPrice,
			gain: newGain,
			actifName: order.actif,
		});
	});
	return mapActifGain;
}

// valide
// voir Notion pour l'exemple, calcul la valeur du squad
export function useOrderTotalGain(orderS: orderState, actif: actifState) {
	// const mapActifPrice = useActifPriceMap(actif);
	// const mapActifGain = useMapActifGain(orderS);
	// let quantity = 0;

	// mapActifGain.forEach((gain) => {
	// 	if (
	// 		!mapActifPrice.has(gain.actifName) ||
	// 		mapActifPrice.get(gain.actifName)!.price === undefined
	// 	)
	// 		return;
	// 	const gainActif =
	// 		(mapActifPrice.get(gain.actifName)!.price - gain.meanPrice) * gain.restant + gain.gain;
	// 	quantity += gainActif;
	// });
	// console.log('percentage: ', orderS.percentage);
	// console.log(
	// 	'quantity: ',
	// 	quantity,
	// 	'donc: ',
	// 	quantity * orderS.percentage + orderS.percentage * useOrderCash(orderS),
	// );
	// console.log('je prefere', useOrderTotalValue(orderS, actif) * orderS.percentage);
	return useOrderTotalValue(orderS, actif) * orderS.percentage;
}

// le total de linvest des gens
export function useOrderInvestedBrut(orderS: orderState) {
	let quantity = 0;
	orderS.orders.forEach((order) => {
		if (order.actif === 'usd') {
			const actualValue = actionQuantityValue(order);
			quantity += actualValue;
		}
	});
	return quantity;
}

//
export function useOrderTotalValue(orderS: orderState, actif: actifState) {
	const mapActifPrice = useActifPriceMap(actif);
	const mapActifGain = useMapActifGain(orderS);
	let quantity = 0;
	mapActifGain.forEach((gain) => {
		if (
			!mapActifPrice.has(gain.actifName) ||
			mapActifPrice.get(gain.actifName)!.price === undefined
		)
			return;
		const gainActif = mapActifPrice.get(gain.actifName)!.price! * gain.restant;
		// + gain.gain;
		quantity += gainActif;
	});
	const invests = useOrderCash(orderS);
	quantity += invests;
	// quantity += invests;
	return quantity;
}

export function useOrderPositions(orderS: orderState) {
	let positions: number = 0;

	orderS.orders.forEach((order) => {
		if (order.state === 1) positions += 1;
	});
	return positions;
}

// renvoie une map contenant tout les actifs dans lequel le squad a investi lie avec leur valeur en dollar
export function useOrderPositionsCleared(orderS: orderState, actif: actifState) {
	const mapPositions = new Map<string, number>();
	const mapActifGain = useMapActifGain(orderS);
	const mapActifPrice = useActifPriceMap(actif);

	actif.actifs.forEach((actif) => {
		// console.log(
		// 	actif.name,
		// 	' passe ?',
		// 	mapActifGain.has(actif.name),
		// 	mapActifPrice.has(actif.name),
		// 	mapActifPrice.has(actif.name) ? mapActifPrice.get(actif.name)!.price : 'none',
		// 	mapActifGain.has(actif.name) ? mapActifGain.get(actif.name)!.restant : 'none',
		// );
		if (!mapActifPrice.has(actif.name) || !mapActifPrice.get(actif.name)!.price) return;
		if (!mapActifGain.has(actif.name) || !mapActifGain.get(actif.name)!.restant) return;
		mapPositions.has(actif.name)
			? mapPositions.set(
					actif.name,
					mapPositions.get(actif.name)! +
						mapActifGain.get(actif.name)!.restant * mapActifPrice.get(actif.name)!.price,
			  )
			: mapPositions.set(
					actif.name,
					mapActifGain.get(actif.name)!.restant * mapActifPrice.get(actif.name)!.price,
			  );
	});
	return mapPositions;
}

export function useOrderInvestments(orderS: orderState) {
	return orderS.orders.filter((order) => order.state === 2);
}

export function useOrderInitiatedTab(orderS: orderState, user: userState) {
	const mapOrderIdVote = new Map<number, voteType>();
	user.votes.forEach((vote) => {
		if (!vote.orderId) return;
		mapOrderIdVote.set(vote.orderId, vote);
	});
	const orderInitiated: orderType[] = orderS.orders.filter(
		(order) => !order.state && order.id && !mapOrderIdVote.has(order.id),
	);
	return orderInitiated;
}
