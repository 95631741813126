import { AddRounded, RemoveRounded } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import React from 'react';
import { squadType } from '../../../store/squad/reducer';
import { userState } from '../../../store/user/reducer';
import { initOpenVote } from '..';

export type parrainageType = {
	email: string;
	message: string;
	squadName?: string;
	token?: string;
	name?: string;
};

function stringToColor(string: string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		// color += `00${value.toString(16)}`.slice(-2);
		color = 'var(--color--GrisText)';
	}
	/* eslint-enable no-bitwise */

	return color;
}

function stringAvatar(name: string) {
	return {
		sx: {
			bgcolor: stringToColor(name),
		},
		children: `${name.charAt(0).toUpperCase()}`,
	};
}

function MembersList(
	currentSquad: squadType | null,
	chooseWhichToOpen: (isAdd: boolean, isLeave: boolean) => void,
	setAnchorEl: any,
	anchorEl: any,
	windowDim: { width: number; height: number },
	setOpenVote: any,
) {
	// if (!currentSquad) return <></>;
	return (
		<div id='tableMember'>
			<div
				className='memberSemiRow hgp2'
				onClick={(event: React.MouseEvent<HTMLElement>) => {
					if (currentSquad) return chooseWhichToOpen(true, false);
					setOpenVote({ ...initOpenVote, openInvite: true })
					// setAnchorEl(anchorEl ? null : event.currentTarget);
				}}
			>
				<div
					className='memberPP'
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						background: 'transparent',
						borderColor: 'white',
						borderWidth: '0.3px',
						border: '2px solid #FFFFFF',
						height: '38px',
						width: '38px',
						// padding: '3px',
					}}
				>
					<AddRounded sx={{ height: '36px', width: '36px' }} />
				</div>
				<div className='infoMemberTable'>
					{currentSquad ? (
						<strong style={{ color: 'var(--color--Blanc)', fontWeight: 700 }} className='hgp'>
							Ajouter <p style={{ margin: 0 }}>un membre</p>
						</strong>
					) : (
						<strong style={{ color: 'var(--color--Blanc)', fontWeight: 700 }} className='hgp'>
							Créer <p style={{ margin: 0 }}>un Squad</p>
						</strong>
					)}
				</div>
			</div>
			{!currentSquad ? (
				<></>
			) : (
				currentSquad.users.map((member, index) => {
					// if (index + 1 === currentSquad.users.length && index % 2) {
					// 	return (
					// 		<div style={{ display: 'flex' }} key={member.nickname}>
					// 			<div className='memberSemiRow'>
					// 				<Avatar variant={windowDim.width > 768 ? 'big' : 'small'} className='memberPP' {...stringAvatar(member.nickname)} />
					// 				<div className='infoMemberTable'>
					// 					<strong>{member.nickname}</strong>
					// 					{member.titre ? member.titre : 'Membre'}
					// 				</div>
					// 			</div>
					// 			<div className='memberSemiRow' key={member.nickname + ' 2'}>
					// 				<div
					// 					style={{
					// 						background: 'transparent',
					// 						height: '50px',
					// 						width: '50px',
					// 						padding: '5px',
					// 					}}
					// 				/>
					// 				<div className='infoMemberTable'>
					// 					{/* <strong>{member.nickname}</strong>Info {member.nickname} */}
					// 				</div>
					// 			</div>
					// 		</div>
					// 	);
					// }
					return (
						<div className='memberSemiRow' key={member.nickname}>
							{/* <img src={user.pp[0]} alt='...' className='memberPP' /> */}
							<Avatar variant={windowDim.width > 768 ? 'big' : 'small'} className='memberPP' {...stringAvatar(member.nickname)} />
							<div className='infoMemberTable'>
								<strong>{member.nickname}</strong>
								{member.titre ? member.titre : 'Membre'}
							</div>
						</div>
					);
				})
			)}
			<div
				className='memberSemiRow hgpr'
				style={{ display: currentSquad ? 'flex' : 'none' }}
				onClick={(event: React.MouseEvent<HTMLElement>) => {
					if (!currentSquad) return;
					chooseWhichToOpen(true, true);
				}}
			>
				<div
					className='memberPP'
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						background: 'transparent',
						borderColor: 'white',
						borderWidth: '0.3px',
						border: '2px solid #FFFFFF',
						height: '38px',
						width: '38px',
						// padding: '3px',
					}}
				>
					<RemoveRounded sx={{ height: '36px', width: '36px' }} />
				</div>
				<div className='infoMemberTable'>
					<strong style={{ color: 'var(--color--Blanc)', fontWeight: 700 }} className='hgpr'>
						Quitter <p style={{ margin: 0 }}>le Squad</p>
					</strong>
				</div>
			</div>
		</div>
	);
}

export default MembersList;
