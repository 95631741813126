import { useActifConnection } from '../../store/actif/hook';
import { useOrderConnection } from '../../store/order/hook';
import { useSquadConnection } from '../../store/squad/hook';
import { useConnection } from '../../store/user/hook';

function useConnectionStore() {
	return {
		connectionUser: useConnection(),
		connectionSquad: useSquadConnection(),
		connectionOrder: useOrderConnection(),
		connectionActif: useActifConnection(),
	};
}

export default useConnectionStore;
