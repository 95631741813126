import React from 'react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Admin from './components/Admin';
import Dashboard from './components/Dashboard';
import Idees from './components/Idees';
import IdeeInvest from './components/Idees/IdeeInvest';
import IdeePays from './components/Idees/IdeePays';
import IdeeTheme from './components/Idees/IdeeTheme';
import IdeeTop from './components/Idees/IdeeTop';
import Layout from './components/Layout';
import Login from './components/Login';
import OrderHistory from './components/OrderHistory';
import Propositions from './components/Propositions';

import store from './store';
import { Documents } from './components/Documents';
import Objectifs from './components/Objectifs';

const App: React.FC = () => {
	return (
		<Provider store={store}>
			<Layout>
				<Routes>
					<Route path='/app/login/' element={<Login />} />
					<Route path='/app/' element={<Dashboard />} />
					<Route path='/app/proposition' element={<Propositions />} />
					<Route path='/app/history' element={<OrderHistory />} />
					<Route path='/app/idee' element={<Idees />} />
					<Route path='/app/idee/top' element={<IdeeTop />} />
					<Route path='/app/idee/pays' element={<IdeePays />} />
					<Route path='/app/idee/theme' element={<IdeeTheme />} />
					<Route path='/app/idee/invest' element={<IdeeInvest />} />
					<Route path='/app/admin' element={<Admin />} />
					<Route path='/app/documents' element={<Documents />} />
					<Route path='/app/objectifs' element={<Objectifs />} />
				</Routes>
			</Layout>
		</Provider>
	);
};

export default App;
