import React, { useCallback, useEffect, useState } from 'react';

import {
	Alert,
	AlertProps,
	Fade,
	styled,
	Tooltip,
	tooltipClasses,
	TooltipProps,
	Typography,
} from '@mui/material';
import { useAddPopup, useRemovePopup } from '../../store/popup/hook';
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import './index.css';
import { orderSlice } from '../../store/order/reducer';
import { useActif } from '../../store/actif/hook';
export interface PopupItemProps extends AlertProps {
	removeAfterMs: number | null;
	message: string;
	severity: string & AlertProps['severity'];
	popKey: string;
}

export const GreyTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: 'var(--color--BleuText)',
		color: 'var(--color--Blanc)',
		boxShadow: theme.shadows[1],
		fontSize: '0.8rem',
	},
}));

function colorSeverity(severity: string) {
	switch (severity) {
		case 'error':
			return 'var(--color--Rouge)';
		case 'warning':
			return 'var(--color--Rouge)';
		case 'info':
			return 'var(--color--BleuFonceText)';
		case 'success':
			return 'var(--color--Vert)';
		default:
			return 'var(--color--Background)';
	}
}

export const PopupItem: React.FC<PopupItemProps> = (props) => {
	const { removeAfterMs, message, severity, popKey } = props;
	const addPopup = useAddPopup();
	const [open, setOpen] = useState(true);
	const removePopup = useRemovePopup();
	const removeThisPopup = useCallback(() => removePopup(popKey), [popKey, removePopup]);
	const [timer, setTimer] = useState<number>(new Date().getTime());
	const actif = useActif();
	const onClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
		removeThisPopup();
	};

	useEffect(() => {
		if (removeAfterMs === null) return undefined;

		const timeout = setTimeout(() => {
			removeThisPopup();
			setOpen(false);
			if (severity === 'info') addPopup(message, 'success');
		}, Math.max(removeAfterMs, actif.lastUpdate && severity === 'info' ? 30000 - Math.round(timer - actif.lastUpdate) : 0));

		return () => {
			clearTimeout(timeout);
		};
	}, [removeAfterMs, removeThisPopup]);

	useEffect(() => {
		const timerRoutine = setInterval(() => {
			setTimer((oldTimer) => oldTimer + 1000);
		}, 1000); // Display the alert 3 seconds (3000 milliseconds) after the component mounts
		return () => clearInterval(timerRoutine); // Clear the timer when the component unmounts
	}, []);
	let test = {};
	if (severity === 'info') test = { icon: false };
	return (
		<Fade in={open}>
			<Alert
				elevation={4}
				variant='filled'
				severity={severity}
				onClose={onClose}
				sx={{
					zIndex: 13001,
					minWidth: '15rem',
					background: colorSeverity(severity),
					mixBlendMode: 'exclusion',
					color: 'var(--color--Blanc)',
				}}
				{...test}
			>
				{severity === 'info' ? (
					<>
						<UpdateRoundedIcon fontSize='inherit' sx={{ width: '1rem', height: '1rem' }} />
						<Typography
							style={{
								fontSize: '0.8rem',
								color: 'var(--color--GrisText)',
								marginRight: '1rem',
							}}
							component='span'
						>
							{actif.lastUpdate ? 30 - Math.round((timer - actif.lastUpdate) / 1000) : '?'}s
						</Typography>
					</>
				) : (
					<></>
				)}
				{message}
			</Alert>
		</Fade>
	);
};
