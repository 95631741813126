import './index.css';
import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Idees: React.FC = () => {
	return (
		<div id='idee'>
			<div className='BlockIdee'>
				<div className='BlockIdeeHeader'>
					<strong style={{ fontSize: '1.2rem', color: 'var(--color--Blanc)' }}>
						Thématique
					</strong>{' '}
					<strong style={{ alignSelf: 'end' }}>TOUT AFFICHER</strong>
				</div>
				<div className='BlockIdeeList'>
					<Link
						to={'/app/idee/theme?=technologie'}
						className='BlockIdeeElement'
						style={{ marginLeft: 0 }}
					>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/technologie.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</Link>
					<Link to={'/app/idee/theme?=renouvelable'} className='BlockIdeeElement'>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/renouvelable.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</Link>
					<Link to={'/app/idee/theme?=construction'} className='BlockIdeeElement'>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/construction.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</Link>
					<Link to={'/app/idee/theme?=internet'} className='BlockIdeeElement'>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/internet.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</Link>
				</div>
			</div>
			<div className='BlockIdee' style={{ marginTop: '2.5rem' }}>
				<div className='BlockIdeeHeader'>
					<strong style={{ fontSize: '1.2rem', color: 'var(--color--Blanc)' }}>
						Investisseurs Pro
					</strong>{' '}
					<strong style={{ alignSelf: 'end' }}>TOUT AFFICHER</strong>
				</div>
				<div className='BlockIdeeList'>
					<Link
						to={'/app/idee/invest?=buffet'}
						className='BlockIdeeElement'
						style={{ marginLeft: 0 }}
					>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/warren.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</Link>
					<Link to={'/app/idee/invest?=ackman'} className='BlockIdeeElement'>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/ackman.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</Link>
					<Link to={'/app/idee/invest?=burry'} className='BlockIdeeElement'>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/burry.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</Link>
					<Link to={'/app/idee/invest?=wood'} className='BlockIdeeElement'>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/wood.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</Link>
				</div>
			</div>
			<div className='BlockIdee' style={{ marginTop: '2.5rem' }}>
				<div className='BlockIdeeHeader'>
					<strong style={{ fontSize: '1.2rem', color: 'var(--color--Blanc)' }}>
						Classement Social
					</strong>{' '}
					<strong style={{ alignSelf: 'end' }}>TOUT AFFICHER</strong>
				</div>
				<div className='BlockIdeeList'>
					<Link
						to={'/app/idee/top?=proposition'}
						className='BlockIdeeElement'
						style={{ marginLeft: 0 }}
					>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/top10proposition.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</Link>
					<Link to={'/app/idee/top?=position'} className='BlockIdeeElement'>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/top10position.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</Link>
					<Link to={'/app/idee/top?=membre'} className='BlockIdeeElement'>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/top10membre.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</Link>
					<Link to={'/app/idee/top?=performance'} className='BlockIdeeElement'>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/top10performance.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</Link>
				</div>
			</div>
			<div className='BlockIdee' style={{ marginTop: '2.5rem' }}>
				<div className='BlockIdeeHeader'>
					<strong style={{ fontSize: '1.2rem', color: 'var(--color--Blanc)' }}>
						Top 10 pays
					</strong>{' '}
					<strong style={{ alignSelf: 'end' }}>TOUT AFFICHER</strong>
				</div>
				<div className='BlockIdeeList'>
					<Link
						to={'/app/idee/pays?=fr'}
						className='BlockIdeeElement'
						style={{ marginLeft: 0 }}
					>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/FR.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</Link>
					<Link to={'/app/idee/pays?=usa'} className='BlockIdeeElement'>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/USA.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</Link>
					<div className='BlockIdeeElement'>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/BRIT.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</div>
					<div className='BlockIdeeElement'>
						<div className='BlockIdeeElementHeader'>
							<img
								alt='...'
								src={require('../../assets/DE.png')}
								className='BlockIdeeElementHeaderImg'
							/>
						</div>
						<div className='BlockIdeeElementBody'>
							<div className='BlockIdeeNumberAction'>14 actions</div>{' '}
							<div className='BlockIdeeDate'>Dernière date - 31/12/2022</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Idees;
