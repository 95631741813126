import { AlertProps } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { AppState, useAppDispatch, useAppSelector } from '..';
import { addPopup, removePopup } from './reducer';

export function usePopup(): AppState['popup'] {
	return useAppSelector((state: AppState) => state.popup);
}

export function useActivePopup(): AppState['popup']['tabPopup'] {
	const listPopup = useAppSelector((state: AppState) => state.popup.tabPopup);
	return useMemo(() => listPopup.filter((item) => item.mustShow), [listPopup]);
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
	const dispatch = useAppDispatch();

	return useCallback(
		(key: string) => {
			dispatch(removePopup({ key }));
		},
		[dispatch],
	);
}

export function useAddPopup(): (
	message: string,
	severity: string & AlertProps,
	removeAfterMs?: number,
	key?: string,
) => void {
	const dispatch = useAppDispatch();

	// useCallback is used to avoid ading twice the same popup
	return useCallback(
		(message: string, severity: string & AlertProps, removeAfterMs?: number, key?: string) => {
			dispatch(addPopup({ message, severity, removeAfterMs, key }));
		},
		[dispatch],
	);
}
