import { Button, Dialog, DialogTitle } from '@mui/material';
import { orderType } from '../../../store/order/reducer';
import { openVoteType } from '../PopUpSignSquad';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { initOpenVote } from '..';

function PopUpVoteEmpty(
	openVote: openVoteType,
	setOpenVote: any,
	initiatedOrders: orderType[],
	navigate: any,
) {
	return (
		<Dialog
			style={{ maxWidth: '300px', minHeight: '200px' }}
			open={openVote.openVote && !initiatedOrders.length}
			onClose={() => setOpenVote(initOpenVote)}
			PaperProps={{
				style: {
					height: '100%',
					width: '100%',
					position: 'relative',
					margin: 0,
					padding: '2rem',
					// background: 'var(--color--Background)',
				},
			}}
		>
			<CloseRoundedIcon
				style={{
					alignSelf: 'flex-end',
					border: '1px solid white',
					borderRadius: '8px',
					height: '1rem',
					width: '1rem',
					cursor: 'pointer',
					padding: '0.1rem',
					// marginBottom: '-0.5rem',
				}}
				onClick={() => setOpenVote(initOpenVote)}
			/>
			<DialogTitle style={{ paddingRight: 0 }}>
				Oh non... 😩
				<div className='sousTitre' style={{ lineHeight: '19px', marginTop: '5px' }}>
					Ton Squad n’a pas fait de proposition d’investissement
				</div>
			</DialogTitle>
			<Button
				style={{
					marginTop: '0.5rem',
					borderRadius: '10px',
					backgroundColor: 'white',
					fontWeight: 700,
					fontSize: '1rem',
				}}
				className='hoverGreenButton'
				onClick={() => navigate('/app/proposition')}
			>
				Proposer
			</Button>
		</Dialog>
	);
}

export default PopUpVoteEmpty;
