import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import './index.css';
import Cookies from 'js-cookie';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import $axios, { setAxiosToken } from '../../utils/axios';
import { Button, CircularProgress, SwipeableDrawer } from '@mui/material';
import { useConnecting, useUser } from '../../store/user/hook';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from '../Sidebar';
import useWindowDimensions from '../../utils/windowDimensions';
import useConnectionStore from '../../utils/connectionStore/connection';
import useDisconnectionStore from '../../utils/disconnectStore/disconnection';
import { useActif, useActifUpdate } from '../../store/actif/hook';
import { actifType, priceType } from '../../store/actif/reducer';
import DisplayPopup from '../DisplayPopup';
import ProfilDashboard from '../smallComponent/profilDashboard';
import SecondHeader from '../secondHeader';

export function updateAll(
	updateActif: any,
	disconnectionUser: any,
	connectionUser: any,
	connectionSquad: any,
	connectionActif: any,
	connectionOrder: any,
	navigate: any,
) {
	$axios.get('actif/getAll').then((data) => {
		const tab = data.data['message'];
		const tabActif: priceType[] = tab.map((actifInfo: actifType) => {
			return {
				price: actifInfo.price,
				evolutionDayPercentage: Math.round(actifInfo.percentage! * 10) / 10,
				actifName: actifInfo.name,
			};
		});
		updateActif(tabActif, new Date().getTime());
		$axios
			.get('user')
			.then((data) => {
				if (!data.data['nickname']) {
					disconnectionUser();
					Cookies.remove('token');
					if (navigate) navigate('/app/login');
					return;
				}
				setAxiosToken(JSON.stringify({ token: data.data['token'] }));
				connectionUser({
					email: data.data['email'] as string,
					nickname: data.data['nickname'] as string,
					token: data.data['token'] as string,
					pathname: data.data['pathname'] as string,
					id: data.data['id'] as number,
					titre: data.data['titre'],
					objectif: data.data['objectif'],
					votes: data.data['votes'],
					shareSquad: data.data['shareSquad'],
				});
				connectionSquad(data.data['squads']);
				connectionActif();
				if (data.data['squads'] && data.data['squads'].length)
					connectionOrder(
						data.data['orders'],
						data.data['squads'][0].nickname,
						data.data['percentage'],
					);
			})
			.catch((e) => {
				disconnectionUser();
				Cookies.remove('token');
				console.error(e);
				if (navigate) navigate('/app/login');
			});
	});
}

const Header: React.FC = () => {
	const user = useUser();
	const actif = useActif();
	const { connectionUser, connectionSquad, connectionOrder, connectionActif } =
		useConnectionStore();

	const { disconnectionUser, disconnectionSquad, disconnectionOrder, disconnectionActif } =
		useDisconnectionStore();
	const connectingUser = useConnecting();
	const updateActif = useActifUpdate();
	const location = useLocation();
	const navigate = useNavigate();
	const [status, setStatus] = useState<string>();
	const [drawer, setDrawer] = useState<boolean>(false);
	const [intervalUpdate, setIntervalUpdate] = useState<NodeJS.Timeout | null>(null);
	const window = useWindowDimensions();
	const width = window.width;

	const getAccessToken = async () => {
		const currentToken = Cookies.get('token');
		if (!currentToken) return;
		if (user.status !== 'disconnected' && user.status !== 'init') return;
		setAxiosToken(currentToken);
		connectingUser();
		$axios
			.get('user')
			.then((data) => {
				if (!data.data['nickname']) {
					disconnectionUser();
					Cookies.remove('token');
					navigate('/app/login');
					return;
				}
				setAxiosToken(JSON.stringify({ token: data.data['token'] }));
				connectionUser({
					email: data.data['email'] as string,
					nickname: data.data['nickname'] as string,
					token: data.data['token'] as string,
					pathname: data.data['pathname'] as string,
					id: data.data['id'] as number,
					titre: data.data['titre'],
					objectif: data.data['objectif'],
					votes: data.data['votes'],
					shareSquad: data.data['shareSquad'],
				});
				connectionSquad(data.data['squads']);
				connectionActif();
				if (data.data['squads'] && data.data['squads'].length)
					connectionOrder(
						data.data['orders'],
						data.data['squads'][0].nickname,
						data.data['percentage'],
					);
			})
			.catch((e) => {
				disconnectionUser();
				Cookies.remove('token');
				console.error(e);
				navigate('/app/login');
			});
	};

	useEffect(() => {
		setStatus(user.status === 'connected' ? 'Deconnexion' : 'Connecte toi');
		const currentToken = Cookies.get('token');
		// console.log('token: ', currentToken);
		if (!currentToken) {
			// console.log('Pas de token => redirection login');
			if (location.pathname !== '/app/login') navigate('/app/login');
			if (user.status === 'init') disconnectionUser();
			return;
		}
		if (user.status === 'disconnected' || user.status === 'init') getAccessToken();
	}, [user.status]);

	useEffect(() => {
		if (actif.status !== 'connected') {
			if (intervalUpdate) clearInterval(intervalUpdate);
			setIntervalUpdate(null);
			return;
		}

		const interval = setInterval(() => {
			updateAll(
				updateActif,
				disconnectionUser,
				connectionUser,
				connectionSquad,
				connectionActif,
				connectionOrder,
				navigate,
			);
		}, 30000);
		setIntervalUpdate(interval);
		$axios.get('actif/getAll').then((data) => {
			const tab = data.data['message'];
			const tabActif: priceType[] = tab.map((actifInfo: actifType) => {
				return {
					price: actifInfo.price,
					evolutionDayPercentage: Math.round(actifInfo.percentage! * 10) / 10,
					actifName: actifInfo.name,
				};
			});
			updateActif(tabActif, new Date().getTime());
		});
		return () => clearInterval(interval);
	}, [actif.status]);

	return (
		<div id='header'>
			<div id="headerOnly">
				<DisplayPopup />
				<React.Fragment key={'left'}>
					<div className='hgp'>
						<MenuIcon
							onClick={() => setDrawer(!drawer)}
							style={{ display: width > 1280 ? 'none' : '', zIndex: 100 }}
						/>
					</div>
					<SwipeableDrawer
						anchor={'left'}
						open={drawer}
						onClose={() => setDrawer(false)}
						onOpen={() => setDrawer(true)}
						PaperProps={{ style: { width: 'var(--nav-bar-width)' } }}
					>
						{<Sidebar widthS={'var(--nav-bar-width)'} setDrawer={setDrawer} />}
					</SwipeableDrawer>
				</React.Fragment>
				<ProfilDashboard isHeader={true} openVote={null} setOpenVote={() => null} />
			</div>
			<SecondHeader isHeader={true} />
		</div>
	);
};

export default Header;
