import { createSlice } from '@reduxjs/toolkit';
import { LiteralType } from 'typescript';

enum actionTypeEnum {
	sell = 0,
	buy = 1,
	deposit = 2,
	widthdraw = 3,
}

enum stateEnum {
	initiated = 0,
	inProgress = 1,
	done = 2,
	refused = 3,
}

export interface orderType {
	id?: number;
	actif: string;
	actionType: actionTypeEnum;
	timeTerm: string;
	price: number;
	realPrice?: number;
	actionQuantity: number;
	dateCreated: LiteralType;
	dateVoted?: LiteralType;
	dateFinalized?: LiteralType;
	state: stateEnum;
	isDisabled?: boolean;
	userId?: number;
	squadId?: number;
}

export interface orderState {
	orders: orderType[];
	squadName: string;
	percentage: number;
	status: string;
}

const initialState: orderState = { orders: [], status: 'init', squadName: '', percentage: 0 };

export const orderSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		connection(state, { payload: { squadName, tabOrders, percentage } }) {
			state.status = 'connected';
			state.squadName = squadName;
			state.orders = tabOrders;
			state.percentage = percentage;
		},
		disconnection(state) {
			state.status = 'disconnected';
			state.squadName = '';
			state.orders = [];
			state.percentage = 0;
		},
	},
});

export const { connection, disconnection } = orderSlice.actions;
export default orderSlice.reducer;
