import axios from 'axios';

let url: string = 'https://thesquad.fr/backend/';

const $axios = axios.create({
	baseURL: url,
	headers: {
		'Access-Control-Allow-Origin': '*',
		// 'Access-Control-Allow-Origin': '*',
	},
});

export default $axios;

export const setAxiosToken = (token: string) => {
	$axios.defaults.headers.common['x-access-token'] = token;
	return $axios;
};
