import { useUser } from '../../../../store/user/hook';
import useWindowDimensions from '../../../../utils/windowDimensions';
import './index.css';

const ProfilInfos = () => {
	const user = useUser();
	const windowDim = useWindowDimensions();
	
	if (windowDim.width < 768) return <></>;
	return (
		<div className="profilInfos">
			<strong style={{ color: 'var(--color--BleuText)' }}>Bonjour 👋</strong>
			<strong
				style={{
					marginTop: '0.1rem',
					fontWeight: '500',
					fontSize: '1rem',
					color: 'var(--color--Blanc)',
				}}
			>
				{user.nickname.charAt(0).toUpperCase() + user.nickname.slice(1)}
			</strong>
		</div>
	)
};

export default ProfilInfos;