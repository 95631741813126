import { useCurrentSquad } from '../../store/squad/hook';
import './index.css';
import { TableauQP } from './tableauQP';
// import { ReactComponent as Logo } from '../../assets/testObj.svg';

// const ObjSvg = require('../../assets/testObj.svg') as string;
export const Documents = () => {
	const currentSquad = useCurrentSquad();
	if (!currentSquad) return (<div>Chargement...</div>);
	return <div id="documentsT">
		<h1 style={{ color: 'var(--color--Blanc)', paddingBottom: '1rem' }}>Documents pour le Squad {currentSquad.nickname}</h1>
		<div id="TtableauQP">
			<TableauQP />
		</div>
	</div>
}