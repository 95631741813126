import React, { useEffect, useState } from 'react';
import { useOrder } from '../../../store/order/hook';
import { orderType } from '../../../store/order/reducer';
import CircleIcon from '@mui/icons-material/Circle';
import './index.css';
import { useActif } from '../../../store/actif/hook';
import { actifType } from '../../../store/actif/reducer';
import { useCurrentSquad, useSquad } from '../../../store/squad/hook';
import { voteType } from '../../Dashboard/PopUpVote/index';
import $axios from '../../../utils/axios';
import useMap from '../../../utils/useMap';
import { GreyTooltip } from '../../DisplayPopup/PopupItem';

interface DisplayOrdersP {
	searchName?: string;
}

const month = ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'];
const tab = ['En cours', 'Approuvé', 'Terminé', 'Rejeté'];
const colorState = [
	'var(--color--BleuText)',
	'var(--color--BleuFonceText)',
	'var(--color--Vert)',
	'var(--color--Rouge)',
];
function literalToDate(date: string) {
	const dateT1 = date.split('T')[0];
	const yearMonthDay = dateT1.split('-');
	if (yearMonthDay && yearMonthDay.length === 3)
		return `${month[parseInt(yearMonthDay[1]) - 1]} ${yearMonthDay[2]}, ${yearMonthDay[0]}`;
	return '';
}

const DisplayOrders: React.FC<DisplayOrdersP> = (props) => {
	const { searchName } = props;
	const order = useOrder();
	const actif = useActif();
	const squad = useSquad();
	const currentSquad = useCurrentSquad();
	const [tabOrder, setTabOrder] = useState<orderType[]>(order.orders.slice().reverse());
	const [MapOrderVote, setMapOrderVote] = useMap<number, voteType[]>();
	const mapActif = new Map<string, actifType>();
	const [customPercent, setCustomPercent] = useState<number>(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setCustomPercent((oldPerc) => {
				if (oldPerc === 100) return oldPerc;
				return oldPerc + 1;
			});
		}, 10);
		return () => clearInterval(interval);
	}, [order.orders.length]);

	actif.actifs.forEach((ac) => mapActif.set(ac.name, ac));

	useEffect(() => {
		if (searchName !== 'DATE') setTabOrder(order.orders.slice().reverse());
		else setTabOrder(order.orders.slice());
	}, [order]);
	useEffect(() => {
		order.orders.forEach((orderN) => {
			if (orderN.id === undefined) return;
			if (!MapOrderVote.has(orderN.id) || orderN.state === 0) {
				$axios
					.get(`vote/${orderN.id}`)
					.then((obj) => {
						const votes = obj.data['votes'];
						setMapOrderVote.set(orderN.id as number, votes);
					})
					.catch((e) => {
						console.error('Fetch data vote error');
					});
			}
		});
	}, [order]);

	if (!currentSquad) return <></>;
	return (
		<div id='orderHistoryList'>
			{tabOrder.map((orderH, index) => {
				if (
					!orderH.id ||
					!mapActif.has(orderH.actif) ||
					!MapOrderVote.has(orderH.id) ||
					!currentSquad ||
					!currentSquad.users
				)
					return <div key={-1 * index}></div>;
				return (
					<div className='orderHistoryRow' key={orderH.id}>
						<div className='orderHistoryDate'>
							{literalToDate(orderH.dateCreated as any) ?? ''}
						</div>
						<div className='orderHistoryStatus'>
							<div
								style={{
									background: colorState[orderH.state],
									borderRadius: '7px',
									padding: '0.25rem',
									paddingRight: '0.5rem',
									paddingLeft: '0.5rem',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<CircleIcon
									sx={{
										height: '7px',
										width: '7px',
										color: 'var(--color--Blanc)',
										marginRight: '0.25rem',
									}}
								/>
								{tab[orderH.state]}
							</div>
						</div>
						<div className='orderHistoryActif'>
							<img
								src={
									!mapActif.get(orderH.actif)!.isCrypto
										? `https://s3-symbol-logo.tradingview.com/${mapActif.get(orderH.actif)!.url
										}--big.svg`
										: `https://s3-symbol-logo.tradingview.com/crypto/${mapActif.get(orderH.actif)!.url
										}--big.svg`
								}
								style={{
									width: `30px`,
									height: `30px`,
									borderRadius: '100px',
									// padding: '5px',
									// backgroundColor: 'var(--)',
									borderWidth: 0,
									marginRight: '0.25rem',
								}}
								alt='...'
							/>
							{orderH.actif.toUpperCase()}
						</div>
						<div className='historyTableScrollBar'>
							<div className='orderHistoryMontant'>{orderH.actionQuantity + ' actions'}</div>
							<div className='orderHistoryPrix'>
								{(orderH.realPrice ? orderH.realPrice : orderH.price).toLocaleString() + ' $'}
							</div>
							<div className='orderHistoryAuthor'>
								{squad.mySquads.length
									? squad.mySquads[0].users.find((user: any) => user.id === orderH.userId)
										?.nickname
									: '?'}
							</div>
							<GreyTooltip
								title={`Oui: ${MapOrderVote.get(orderH.id)!.filter((vv) => vv.valueVote).length
									} Non: ${MapOrderVote.get(orderH.id)!.filter((vv) => !vv.valueVote).length}`}
								placement='top'
							>
								<div className='orderHistoryVote'>
									<div className='orderHistoryVoteContainer'>
										<div
											className='orderHistoryVoteBar'
											style={{
												backgroundImage: `linear-gradient(to right, ${orderH.state === 3
													? 'var(--color--Rouge)'
													: orderH.state === 2
														? 'var(--color--t150)'
														: 'var(--color--Blanc)'
													} ${(MapOrderVote.get(orderH.id)!.length /
														currentSquad.users.length) *
													customPercent
													}%, transparent  ${(MapOrderVote.get(orderH.id)!.length /
														currentSquad.users.length) *
													customPercent
													}%)`,
											}}
										>
											<div
												style={{
													height: '6px',
													border: '1px white solid',
													position: 'absolute',
													left: `${(currentSquad && currentSquad.users.length
														? Math.ceil(currentSquad.users.length / 2) /
														currentSquad.users.length
														: 1) * 100
														}%`,
													bottom: '2px',
												}}
											/>
										</div>
									</div>
								</div>
							</GreyTooltip>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default DisplayOrders;
