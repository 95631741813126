import { useCallback, useEffect, useState } from 'react';
import { orderState } from '../../../store/order/reducer';
import React from 'react';
import { useOrder } from '../../../store/order/hook';
import { useCurrentSquad, useSquad } from '../../../store/squad/hook';
import { useUser } from '../../../store/user/hook';
import './index.css';
import useMap from '../../../utils/useMap';
import { userSquadType } from '../../../store/squad/reducer';
import { GreyTooltip } from '../../DisplayPopup/PopupItem';
import useWindowDimensions from '../../../utils/windowDimensions';
interface DisplayBarsP { }

function cumulativeSum(arr: number[]): number[] {
	// Declare the accumulator as a number and initialize it with the first element of the array
	return arr.reduce(
		(acc: number[], val, i) => {
			// Push the sum of the current element and the previous element to the accumulator
			acc.push(val + (acc[i - 1] || 0));
			return acc;
		},
		[arr[0]],
	);
}

export const colorScale = [
	'#2A4D85',
	'#1E3573',
	'#272D91',
	'#2B2861',
	'#476585',
	'#4775AA',
	'#478EAA',
	'#306273',
	'#516273',
	'#4358C4',
	'#2D3C85',
	'#182F85',
	'#175FB9',
	'#4154BF',
	'#035AA6',
	'#546CB3',
	'#42558C',
	'#7292F2',
	'#4E64A6',
	'#0461B3',
	'#034C8C',
	'#035AA6',
	'#03588C',
	'#046FB3',
	'#0367A6',
];

const DisplayBars: React.FC = () => {
	const order = useOrder();
	const [mapUserIdToUser, setMapUserIdToUser] = useMap<number, userSquadType>();
	const currentSquad = useCurrentSquad();
	const user = useUser();
	const window = useWindowDimensions();
	const tabPercentagesNF =
		currentSquad && currentSquad.percentages
			? currentSquad.percentages
				.filter((per) => per.userId !== user.id && mapUserIdToUser.get(per.userId)?.nickname)
				.sort((a, b) => a.amountUserInvested - b.amountUserInvested)
				.map((per) => {
					return {
						perc: per.lastValueUserInvestment / per.lastAmountSquad,
						userId: per.userId,
					};
				})
			: [];
		tabPercentagesNF.unshift({ perc: order.percentage, userId: user.id });
	const ids = tabPercentagesNF.map(({ userId }) => userId);
	const tabPercentages = tabPercentagesNF.filter(({ userId }, index) => !ids.includes(userId, index + 1));

	const [customOrderPerc, setCustomeOrderPerc] = useState<number>(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setCustomeOrderPerc((oldPerc) => {
				if (oldPerc === Math.round(order.percentage * 1000)) return oldPerc;
				if (oldPerc > order.percentage * 1000) return oldPerc - 1;
				return oldPerc + 1;
			});
		}, 10);
		return () => clearInterval(interval);
	}, [order]);
	useEffect(() => {
		if (!currentSquad) return;
		currentSquad.users.forEach((userS) => {
			if (!userS.id) return;
			if (mapUserIdToUser.has(userS.id)) return;
			setMapUserIdToUser.set(userS.id, userS);
		});
	}, [currentSquad]);
	return (
		<div
			style={{
				display: 'flex',
				width: window.width > 768 ? '100%' : 'calc(100% - 0.5rem)',
				marginLeft: window.width > 768 ? 0 : '0.5rem',
				position: 'relative',
				height: '1.5rem',
			}}
		>
			{tabPercentages.map((per, i) => {
				return (
					<GreyTooltip
						key={i}
						title={`${mapUserIdToUser.get(per.userId)?.nickname} possède ${Math.round(
							per.perc * 100,
						)}% du Squad`}
						placement={window.width > 768 ? 'bottom' : 'top-start'}
					>
						<div
							className={`hoverBar${i % 25}`}
							style={{
								height: '100%',
								width: `${per.perc * 100}%`,
								background: i ? 'var(--color--SecondBackground)' : 'var(--color--t150)',
								marginLeft: '2px',
								borderRadius: '6px',
								cursor: 'pointer',
							}}
						/>
					</GreyTooltip>
				);
			})}
		</div>
	);
};

export default DisplayBars;

/*
<div
				className='displayBar'
				style={{
					background:
						customOrderPerc > 100 ? 'var(--color--t150)' : 'var(--color--SecondBackground)',
					backgroundImage:
						customOrderPerc > 100
							? ''
							: `linear-gradient(to right, var(--color--t150) ${
									customOrderPerc - 0
							  }%, var(--color--SecondBackground) ${customOrderPerc - 0}%)`,
				}}
			/>
			<div
				className='displayBar'
				style={{
					background:
						customOrderPerc > 200 ? 'var(--color--t150)' : 'var(--color--SecondBackground)',
					backgroundImage:
						customOrderPerc > 200 || customOrderPerc < 100
							? ''
							: `linear-gradient(to right, var(--color--t150) ${
									customOrderPerc - 100
							  }%, var(--color--SecondBackground) ${customOrderPerc - 100}%)`,
				}}
			/>
			<div
				className='displayBar'
				style={{
					background:
						customOrderPerc > 300 ? 'var(--color--t150)' : 'var(--color--SecondBackground)',
					backgroundImage:
						customOrderPerc > 300 || customOrderPerc < 200
							? ''
							: `linear-gradient(to right, var(--color--t150) ${
									customOrderPerc - 200
							  }%, var(--color--SecondBackground) ${customOrderPerc - 200}%)`,
				}}
			/>
			<div
				className='displayBar'
				style={{
					background:
						customOrderPerc > 400 ? 'var(--color--t150)' : 'var(--color--SecondBackground)',
					backgroundImage:
						customOrderPerc > 400 || customOrderPerc < 300
							? ''
							: `linear-gradient(to right, var(--color--t150) ${
									customOrderPerc - 300
							  }%, var(--color--SecondBackground) ${customOrderPerc - 300}%)`,
				}}
			/>
			<div
				className='displayBar'
				style={{
					background:
						customOrderPerc > 500 ? 'var(--color--t150)' : 'var(--color--SecondBackground)',
					backgroundImage:
						customOrderPerc > 500 || customOrderPerc < 400
							? ''
							: `linear-gradient(to right, var(--color--t150) ${
									customOrderPerc - 400
							  }%, var(--color--SecondBackground) ${customOrderPerc - 400}%)`,
				}}
			/>
			<div
				className='displayBar'
				style={{
					background:
						customOrderPerc > 600 ? 'var(--color--t150)' : 'var(--color--SecondBackground)',
					backgroundImage:
						customOrderPerc > 600 || customOrderPerc < 500
							? ''
							: `linear-gradient(to right, var(--color--t150) ${
									customOrderPerc - 500
							  }%, var(--color--SecondBackground) ${customOrderPerc - 500}%)`,
				}}
			/>
			<div
				className='displayBar'
				style={{
					background:
						customOrderPerc > 700 ? 'var(--color--t150)' : 'var(--color--SecondBackground)',
					backgroundImage:
						customOrderPerc > 700 || customOrderPerc < 600
							? ''
							: `linear-gradient(to right, var(--color--t150) ${
									customOrderPerc - 600
							  }%, var(--color--SecondBackground) ${customOrderPerc - 600}%)`,
				}}
			/>
			<div
				className='displayBar'
				style={{
					background:
						customOrderPerc > 800 ? 'var(--color--t150)' : 'var(--color--SecondBackground)',
					backgroundImage:
						customOrderPerc > 800 || customOrderPerc < 700
							? ''
							: `linear-gradient(to right, var(--color--t150) ${
									customOrderPerc - 700
							  }%, var(--color--SecondBackground) ${customOrderPerc - 700}%)`,
				}}
			/>
			<div
				className='displayBar'
				style={{
					background:
						customOrderPerc > 900 ? 'var(--color--t150)' : 'var(--color--SecondBackground)',
					backgroundImage:
						customOrderPerc > 900 || customOrderPerc < 800
							? ''
							: `linear-gradient(to right, var(--color--t150) ${
									customOrderPerc - 800
							  }%, var(--color--SecondBackground) ${customOrderPerc - 800}%)`,
				}}
			/>
			<div
				className='displayBar'
				style={{
					background:
						customOrderPerc > 1000 ? 'var(--color--t150)' : 'var(--color--SecondBackground)',
					backgroundImage:
						customOrderPerc > 1000 || customOrderPerc < 900
							? ''
							: `linear-gradient(to right, var(--color--t150) ${
									customOrderPerc - 900
							  }%, var(--color--SecondBackground) ${customOrderPerc - 900}%)`,
				}}
			/>
*/
