import {
	Avatar,
	Box,
	Button,
	Dialog,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { initOpenVote } from '..';
import { userState } from '../../../store/user/reducer';
import { openVoteType } from '../PopUpSignSquad';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import $axios from '../../../utils/axios';
import { useAddPopup } from '../../../store/popup/hook';
import { useConnection, useUser } from '../../../store/user/hook';
import { updateAll } from '../../Header';
import { useActifUpdate } from '../../../store/actif/hook';
import useConnectionStore from '../../../utils/connectionStore/connection';
import useDisconnectionStore from '../../../utils/disconnectStore/disconnection';
import { stringAvatar } from '../../smallComponent/profilDashboard';

const MenuProps = {
	root: {
		style: {
			BackgroundColor: 'transparent !important',
		},
	},
	PaperProps: {
		root: {
			style: {
				BackgroundColor: 'transparent !important',
			},
		},
		style: {
			display: 'flex',
			justifyContent: 'center',
			marginTop: '-0.6rem',
			width: '5rem',
			BackgroundColor: 'transparent !important',
			borderRadius: '10px',
		},
	},
};
const MenuProps2 = {
	PaperProps: {
		style: {
			display: 'flex',
			justifyContent: 'center',
			marginTop: '-0.6rem',
			width: '200px',
			BackgroundColor: 'transparent',
			borderRadius: '10px',
		},
	},
};
const titres = ['Néophyte 🐥', 'Aspirant 📚', 'Confirmé 🎖'];
const objectifs = [
	'Nouvelle voiture 🚗',
	'Nouvelle maison 🏠',
	'Voyage à la montagne 🏕',
	'Voyage à la mer 🏖',
	'Mariage 💍',
	'Nouveau téléphone 📱',
	'Fonds de secours 🆘',
	'Retraite 🛄',
	'Bateau ⛵️',
];

interface PopUpProfilP {
	openVote: openVoteType;
	setOpenVote: any;
}

interface profilInfosType {
	objectif: string;
	titre: string;
}

const PopUpProfil: React.FC<PopUpProfilP> = (props) => {
	const { openVote, setOpenVote } = props;
	const user = useUser();
	const [profilInfos, setProfilInfos] = useState<profilInfosType>({
		objectif: user.objectif ?? '',
		titre: user.titre ?? '',
	});
	const addPopup = useAddPopup();
	const updateActif = useActifUpdate();
	const { connectionUser, connectionSquad, connectionOrder, connectionActif } =
		useConnectionStore();
	const { disconnectionUser, disconnectionSquad, disconnectionOrder, disconnectionActif } =
		useDisconnectionStore();
	const sendProfilInfo = () => {
		if (!profilInfos.objectif || !profilInfos.titre) return;
		setOpenVote(initOpenVote);
		if (profilInfos.objectif === user.objectif && profilInfos.titre === user.titre) return;
		$axios
			.put('user/changeUserInfo', {
				titre: profilInfos.titre,
				objectif: profilInfos.objectif,
			})
			.then(() => {
				addPopup('Profil Modifié', 'success');
				updateAll(
					updateActif,
					disconnectionUser,
					connectionUser,
					connectionSquad,
					connectionActif,
					connectionOrder,
					undefined,
				);
			})
			.catch(() => addPopup('Mises à Jour du Profil Echouée', 'error'));
	};
	return (
		<Dialog
			open={openVote.openProfil}
			onClose={() => setOpenVote({ ...initOpenVote })}
			sx={{
				minWidth: '250px',
				minHeight: '250px',
			}}
			PaperProps={{
				style: {
					minWidth: '250px',
					minHeight: '250px',
					height: '100%',
					width: '100%',
					position: 'relative',
					margin: 0,
					padding: '2rem',
					// background: 'var(--color--Background)',
				},
			}}
		>
			<CloseRoundedIcon className='leaveButton' onClick={() => setOpenVote(initOpenVote)} />
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Box
					sx={{
						display: 'flex',
						height: '50px',
						width: '100%',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}
				>
					<Avatar
						{...stringAvatar(user.nickname)}
						className='memberPP'
						style={{
							height: '50px',
							width: '50px',
							color: 'var(--color--Blanc)',
							boxShadow: '0px 0px 3px 0px var(--color--GrisText)',
							cursor: 'pointer',
						}}
					/>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							marginLeft: '1rem',
							fontWeight: 700,
							fontSize: '1rem',
							// letterSpacing: '-0.03rem',
						}}
					>
						{user.nickname.charAt(0).toUpperCase() + user.nickname.slice(1)}
						<div
							style={{
								fontWeight: 400,
								fontSize: '0.8rem',
								color: 'var(--color--GrisText)',
							}}
						>
							{user.titre ?? ''}
						</div>
					</div>
				</Box>
				<Box
					style={{
						width: '100%',
						display: 'flex',
						position: 'relative',
						marginTop: '2rem',
					}}
				>
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: 'column',
							height: '3rem',
						}}
					>
						<div
							style={{
								color: 'var(--color--BleuFonceText)',
								fontSize: '0.6rem',
								display: 'flex',
								height: '0.6rem',
							}}
						>
							<strong>TITRE</strong>
						</div>

						<FormControl
							sx={{ m: 0, width: '49%', height: '2rem', color: 'var(--color--Blanc)' }}
						>
							<Select
								value={profilInfos.titre}
								onChange={(event: SelectChangeEvent) =>
									setProfilInfos({
										...profilInfos,
										titre: event.target.value,
									})
								}
								color='info'
								className='testBorderBlanc hgp3'
								sx={{ bordeWidth: '2px', fontSize: '0.8rem' }}
								MenuProps={MenuProps}
							>
								{titres.map((titre) => {
									return (
										<MenuItem
											key={titre}
											value={titre}
											sx={{
												justifyContent: 'center',
												alignItems: 'center',
												fontSize: '0.8rem',
												color: 'var(--color--Blanc)',
											}}
										>
											{titre}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>
				</Box>
				<Box
					style={{
						width: '100%',
						display: 'flex',
						position: 'relative',
						marginTop: '1rem',
					}}
				>
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: 'column',
							height: '3rem',
						}}
					>
						<div
							style={{
								color: 'var(--color--BleuFonceText)',
								fontSize: '0.6rem',
								display: 'flex',
								height: '0.6rem',
							}}
						>
							<strong>OBJECTIF</strong>
						</div>

						<FormControl
							sx={{ m: 0, width: '100%', height: '2rem', color: 'var(--color--Blanc)' }}
						>
							<Select
								value={profilInfos.objectif}
								onChange={(event: SelectChangeEvent) =>
									setProfilInfos({
										...profilInfos,
										objectif: event.target.value,
									})
								}
								color='info'
								className='testBorderBlanc hgp3'
								sx={{ bordeWidth: '2px', fontSize: '0.8rem' }}
								// open={true}
								// input={<OutlinedInput />}
								MenuProps={MenuProps2}
								// inputProps={{ 'aria-label': 'Without label' }}
							>
								{objectifs.map((objectif) => {
									// if (objectif === 'Durée de la proposition') return <MenuItem></MenuItem>;
									return (
										<MenuItem
											// hidden={objectif === 'Durée de la proposition' ? true : false}
											key={objectif}
											value={objectif}
											// className='testBorderBlanc hgp3'
											sx={{
												justifyContent: 'center',
												alignItems: 'center',
												fontSize: '0.8rem',
												color: 'var(--color--Blanc)',
												// border: '1px solid white',
												// borderRadius: '10px',
												// width: '49%',
											}}
										>
											{objectif}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>
				</Box>
				<Button
					sx={{
						marginTop: '1.5rem',
						borderRadius: '7px',
						height: '2rem',
						width: '100%',
						// border: '2px solid white',
					}}
					variant='fullBlanco'
					onClick={() => sendProfilInfo()}
				>
					Confirmer
				</Button>
			</Box>
		</Dialog>
	);
};

export default PopUpProfil;
