import React, { ReactNode } from 'react';
import './index.css';
import Header from '../Header';
import Sidebar from '../Sidebar';
import SecondHeader from '../secondHeader';
import useWindowDimensions from '../../utils/windowDimensions';

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<div id='layout'>
			<Header />
			<div
				style={{
					maxWidth: '100%',
					maxHeight: '100%',
					width: '100%',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<SecondHeader />
				{children}
			</div>
			<Sidebar />
		</div>
	);
};

export default Layout;
