import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AppState, useAppSelector } from '..';
import { actifState, connection, disconnection, priceType, update } from './reducer';

export function useActifConnection(): () => void {
	const dispatch = useDispatch();

	return useCallback(() => dispatch(connection({})), [dispatch]);
}

export function useActifDisconnection(): () => void {
	const dispatch = useDispatch();

	return useCallback(() => dispatch(disconnection({})), [dispatch]);
}

export function useActifUpdate(): (prices: priceType[], lastUpdate: number) => void {
	const dispatch = useDispatch();

	return useCallback(
		(prices: priceType[], lastUpdate: number) => {
			dispatch(update({ prices, lastUpdate }));
		},
		[dispatch],
	);
}

export function useActif(): AppState['actif'] {
	return useAppSelector((state: AppState) => state.actif);
}

export function useActifPriceMap(actif: actifState) {
	const mapPrice: Map<string, priceType> = new Map();

	actif.prices.forEach((price) => {
		mapPrice.set(price.actifName, price);
	});
	return mapPrice;
}
