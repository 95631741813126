import React from 'react';
import './index.css';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { Link } from 'react-router-dom';

const tabTheme = ['fr', 'usa', 'uk', 'de'];

const backgroundTheme = [
	'linear-gradient(80.12deg, #131938 39.24%, #135B9E 101.31%)',
	'linear-gradient(80.12deg, #131938 39.24%, #135B9E 101.31%)',
	'linear-gradient(80.12deg, #131938 39.24%, #135B9E 101.31%)',
	'linear-gradient(80.12deg, #131938 39.24%, #135B9E 101.31%)',
];

const images = [
	require('../../../assets/FR.png'),
	require('../../../assets/USA.png'),
	require('../../../assets/BRIT.png'),
	require('../../../assets/DE.png'),
];
const IdeePays = () => {
	const queryParameters = new URLSearchParams(window.location.search);
	const theme = queryParameters.get('');
	if (!theme) return <></>;
	const realTheme = tabTheme.indexOf(theme);
	if (realTheme < 0) return <></>;
	return (
		<div id='ideePays'>
			<div id='chevronsIdee'>
				<Link
					to='/app/idee'
					style={{
						height: '2rem',
						width: '2rem',
						background: 'var(--color--SecondBackground)',
						textDecoration: 'none',
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '100px',
						color: 'var(--color--Blanc)',
					}}
					className='hgp'
				>
					<ChevronLeftRoundedIcon style={{ width: '2rem', height: '2rem' }} />
				</Link>
				<div
					style={{
						height: '2rem',
						width: '2rem',
						background: 'var(--color--SecondBackground)',
						textDecoration: 'none',
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '100px',
						marginLeft: '1rem',
						color: 'var(--color--GrisText)',
					}}
					// className='hgp'
				>
					<ChevronRightRoundedIcon style={{ width: '2rem', height: '2rem' }} />
				</div>
			</div>
			<div id='ideePaysBody' style={{ background: backgroundTheme[realTheme] }}>
				<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
					<img
						src={images[realTheme]}
						style={{ height: '170px', width: '170px' }}
						alt='idee'
					/>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							justifyContent: 'space-between',
							height: '100%',
							marginLeft: '2rem',
							fontWeight: 700,
							fontSize: '0.9rem',
						}}
					>
						THEMATIQUE
						<div style={{ fontSize: '4rem', color: 'var(--color--Blanc)', fontWeight: 900 }}>
							Nouvelles Technologies
						</div>
						<div style={{ fontSize: '0.8rem', fontWeight: 400 }}>32 actions, 4 cryptos</div>
					</div>
				</div>
				<div
					style={{
						width: '100%',
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-end',
					}}
				>
					<div id='headerTableHistory' style={{ marginTop: '4rem' }}>
						<div className='headerHistory headerHistoryDate' style={{ width: '1rem' }}>
							#
						</div>
						<div
							className='headerHistory headerHistoryStatus'
							style={{ paddingLeft: '1rem', width: '2rem' }}
						>
							ACTIF
						</div>
						<div className='headerHistory headerHistoryActif'>NOM</div>
						<div className='headerHistory headerHistoryMontant'></div>
						<div className='headerHistory headerHistoryPrix'>SECTEUR</div>
						<div className='headerHistory headerHistoryAuthor'>PAYS</div>
						<div className='headerHistory headerHistoryVote'>CAPITALISATION</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default IdeePays;
