import { Button, FormControl, Input, InputAdornment, InputLabel } from '@mui/material';
import React from 'react';
import { useUser } from '../../store/user/hook';
import $axios from '../../utils/axios';
import './index.css';

const Admin: React.FC = () => {
	const [doubleConfirm, setDoubleConfirm] = React.useState<number>(0);
	const user = useUser();
	const [csv, setCSV] = React.useState<any>([]);
	const [userEraseSquad, setUserEraseSquad] = React.useState<string>('');
	const [isPrelaunch, setIsPrelaunch] = React.useState<boolean>(false);
	function getCSVUser() {
		setIsPrelaunch(false);
		$axios.get('user/userAll').then((res) => {
			const tabUsers = res.data['message'];
			// let tabCSV = 'nickname;email;country;date\n';
			const csvString = [
				['Nickname', 'Email', 'Squad', 'Country', 'Date'],
				...tabUsers.map((item: any) => [
					item.nickname,
					item.email,
					item.squads.length ? item.squads[0].nickname : 'No Squad',
					item.country,
					item.createdAt,
				]),
			].map((e) => e.join(','));
			setCSV(csvString);
		});
	}

	function getCSVSquad() {
		setIsPrelaunch(false);
		$axios.get('squad/squadAll').then((res) => {
			const tabUsers = res.data;
			// let tabCSV = 'nickname;email;country;date\n';
			const csvString = [
				['Nickname', 'Size', 'Orders', 'Date'],
				...tabUsers.map((item: any) => [
					item.nickname,
					item.users.length,
					item.orders.length,
					// item.votes.length,
					// item.country,
					item.createdAt,
				]),
			].map((e) => e.join(','));
			setCSV(csvString);
		});
	}

	function getCSVPrelaunch() {
		setIsPrelaunch(true);
		$axios.get('prelaunch/prelaunchAll').then((res) => {
			const tabUsers = res.data;
			// let tabCSV = 'nickname;email;country;date\n';
			const csvString = [
				['Email', 'Format', 'Date'],
				...tabUsers.map((item: any) => [item.email, item.format, item.createdAt]),
			].map((e) => e.join(','));
			setCSV(csvString);
		});
	}

	function getActionsRequestsInfos() {
		setIsPrelaunch(false);
		$axios.get('actif/status').then((res) => {
			setCSV([res.data.message]);
		})
	}

	function resetDBPrelaunch() {
		if (!doubleConfirm) {
			setDoubleConfirm(1);
			return;
		}
		$axios.get('prelaunch/reset').then((res) => {
			setCSV(['DB PRELAUNCH RESET']);
		}
		);
		setDoubleConfirm(0);
	}

	function banUser() {
		console.log('ca va ban', userEraseSquad);
		$axios.put('user/destroy', { userMail: userEraseSquad }).then((res) => {
			setCSV([`user left squad: ${res.data.message}`]);
		}
		);
		setDoubleConfirm(0);
	}

	if (user.email !== 'marcailloupa@gmail.com' && user.email !== 'elias.saci@gmail.com')
		return <></>;
	return (
		<div id='admin'>
			<Button style={{ borderRadius: '10px', margin: '3rem', background: 'red', color: 'yellow' }} onClick={() => resetDBPrelaunch()}>
				{doubleConfirm ? "CONFIRM RESET" : "RESET PRELAUNCH DB ATTENTION"}
			</Button>
			<Button style={{ borderRadius: '10px', margin: '3rem' }} onClick={() => getCSVUser()}>
				Click Pour Avoir la Table des Users
			</Button>
			<Button style={{ borderRadius: '10px', margin: '3rem' }} onClick={() => getCSVSquad()}>
				Click Pour Avoir la Table des Squads
			</Button>
			<Button style={{ borderRadius: '10px', margin: '3rem' }} onClick={() => getCSVPrelaunch()}>
				Click Pour Avoir la Table des Prelaunch
			</Button>
			<Button style={{ borderRadius: '10px', margin: '3rem' }} onClick={() => getActionsRequestsInfos()}>
				Click Pour voir l'etat des requetes titres
			</Button>
			<FormControl variant='outlined'>
				<InputLabel htmlFor='ok' sx={{ color: 'white' }}>
					E-mail Utilisateurs
				</InputLabel>
				<Input
					color='info'
					sx={{
						color: 'white',
						// height: '2rem',
						padding: 0,
						borderColor: 'white !important',
						borderWidth: 2,
						borderTopWidth: '1px',
						// background: 'var(--color--BleuFonceText)',
					}}
					value={userEraseSquad}
					onChange={(value) => setUserEraseSquad(value.target.value)}
				/>
			</FormControl>
			<Button style={{ borderRadius: '10px', margin: '3rem', background: 'red', color: 'yellow' }} onClick={() => banUser()}>
				Retirer de son squad: {userEraseSquad}
			</Button>
			<div id='csvAdmin'>
				{isPrelaunch ? 'FORMAT: 0: thesquad.fr, 1: linkedin, 2: google' : ''}
				{csv.map((user: any) => {
					return (
						<div>
							{user} <br />
						</div>
					);
				})}
			</div>
		</div >
	);
};

export default Admin;
