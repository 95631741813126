import { createSlice } from '@reduxjs/toolkit';
import { voteType } from '../../components/Dashboard/PopUpVote';

export interface userState {
	status: string;
	nickname: string;
	email: string;
	token: string;
	pp: any[];
	pathname?: string;
	objectif?: string;
	titre?: string;
	id: number;
	votes: voteType[];
	shareSquad: string;
}

const pps: any[] = [];

const initialState: userState = {
	status: 'init',
	nickname: '',
	email: '',
	token: '',
	pp: pps,
	pathname: '',
	objectif: '',
	id: -1,
	votes: [],
	shareSquad: '',
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		connecting(state) {
			state.status = 'connecting';
		},
		connection(
			state,
			{
				payload: {
					nickname,
					email,
					token,
					pathname,
					id,
					titre = undefined,
					objectif = undefined,
					votes,
					shareSquad,
				},
			},
		) {
			state.nickname = nickname;
			state.email = email;
			state.token = token;
			state.pathname = pathname;
			state.status = 'connected';
			state.id = id;
			state.titre = titre;
			state.objectif = objectif;
			state.votes = votes;
			state.shareSquad = shareSquad;
		},
		disconnection(state) {
			state.nickname = '';
			state.email = '';
			state.token = '';
			state.pathname = '';
			state.id = -1;
			state.objectif = undefined;
			state.titre = undefined;
			state.status = 'disconnected';
			state.votes = [];
			state.shareSquad = '';
		},
	},
});

export const { connecting, connection, disconnection } = userSlice.actions;
export default userSlice.reducer;
