import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AppState, useAppSelector } from '..';
import { connection, disconnection, squadType } from './reducer';

export function useSquadConnection(): (tabSquads: squadType[]) => void {
	const dispatch = useDispatch();

	return useCallback(
		(tabSquads: squadType[]) => {
			dispatch(connection({ tabSquads }));
		},
		[dispatch],
	);
}

export function useSquadDisconnection(): () => void {
	const dispatch = useDispatch();

	return useCallback(() => {
		dispatch(disconnection());
	}, [dispatch]);
}

export function useSquad(): AppState['squad'] {
	return useAppSelector((state: AppState) => state.squad);
}

export function useCurrentSquad(): AppState['squad']['mySquads'][0] | null {
	return useAppSelector((state: AppState) =>
		state.squad.mySquads.length ? state.squad.mySquads[0] : null,
	);
}
