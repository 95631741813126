import React from 'react';
import './index.css';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { Link } from 'react-router-dom';

const tabTheme = ['technologie', 'renouvelable', 'construction', 'internet'];

const backgroundTheme = [
	'linear-gradient(80.12deg, var(--color--SecondBackground) 39.24%, #F6AB75 101.31%)',
	'linear-gradient(80.12deg, var(--color--SecondBackground) 39.24%, #1CCE83 101.31%)',
	'linear-gradient(80.12deg, var(--color--SecondBackground) 39.24%, #A4A29D 101.31%)',
	'linear-gradient(80.12deg, var(--color--SecondBackground) 39.24%, #FF7B31 101.31%)',
];

const images = [
	require('../../../assets/renouvelable.png'),
	require('../../../assets/renouvelable.png'),
	require('../../../assets/construction.png'),
	require('../../../assets/internet.png'),
];
const IdeeTheme = () => {
	const queryParameters = new URLSearchParams(window.location.search);
	const theme = queryParameters.get('');
	if (!theme) return <></>;
	const realTheme = tabTheme.indexOf(theme);
	if (realTheme < 0) return <></>;
	return (
		<div id='ideeTheme'>
			<div id='chevronsIdee'>
				<Link
					to='/app/idee'
					style={{
						height: '2rem',
						width: '2rem',
						background: 'var(--color--SecondBackground)',
						textDecoration: 'none',
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '100px',
						color: 'var(--color--Blanc)',
						zIndex: 13001,
					}}
					className='hgp'
				>
					<ChevronLeftRoundedIcon style={{ width: '2rem', height: '2rem' }} />
				</Link>
				<div
					style={{
						height: '2rem',
						width: '2rem',
						background: 'var(--color--SecondBackground)',
						textDecoration: 'none',
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '100px',
						marginLeft: '1rem',
						color: 'var(--color--GrisText)',
					}}
					// className='hgp'
				>
					<ChevronRightRoundedIcon style={{ width: '2rem', height: '2rem' }} />
				</div>
			</div>
			<div id='ideeThemeBody' style={{ background: backgroundTheme[realTheme] }}>
				<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
					<img
						src={images[realTheme]}
						style={{ height: '170px', width: '170px', borderRadius: '10px' }}
						alt='idee'
					/>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							justifyContent: 'space-between',
							height: '100%',
							marginLeft: '2rem',
							fontWeight: 700,
							fontSize: '0.9rem',
						}}
					>
						THEMATIQUE
						<div style={{ fontSize: '4rem', color: 'var(--color--Blanc)', fontWeight: 900 }}>
							Nouvelles Technologies
						</div>
						<div style={{ fontSize: '0.8rem', fontWeight: 400 }}>32 actions, 4 cryptos</div>
					</div>
				</div>
				<div
					style={{
						width: '100%',
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-end',
					}}
				>
					<div id='headerTableHistory' style={{ marginTop: '4rem' }}>
						<div className='headerHistory headerHistoryDate'>ACTIF</div>
						<div className='headerHistory headerHistoryStatus'></div>
						<div className='headerHistory headerHistoryActif'></div>
						<div className='headerHistory headerHistoryMontant'>SECTEUR</div>
						<div className='headerHistory headerHistoryPrix'>PAYS</div>
						<div className='headerHistory headerHistoryAuthor'>PRIX</div>
						<div className='headerHistory headerHistoryVote'></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default IdeeTheme;
