import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './index.css';
import DisplayOrders from './DisplayOrders';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { useCurrentSquad } from '../../store/squad/hook';

const OrderHistory: React.FC = () => {
	const [filter, setFilter] = useState<string>('');
	const currentSquad = useCurrentSquad();

	return (
		<div id='orderHistory'>
			<div
				style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-end',
				}}
			>
				<div id='headerTableHistory'>
					<div
						className='headerHistory headerHistoryDate'
						onClick={() => setFilter(filter === 'DATE' ? '' : 'DATE')}
					>
						DATE
					</div>
					<div className='headerHistory headerHistoryStatus'>STATUS</div>
					<div className='historyTableScrollBar'>
						<div className='headerHistory headerHistoryActif'>ACTIF</div>
						<div className='headerHistory headerHistoryMontant'>MONTANT</div>
						<div className='headerHistory headerHistoryPrix'>PRIX</div>
						<div className='headerHistory headerHistoryAuthor'>CREE PAR</div>
						<div className='headerHistory headerHistoryVote'>RESULTAT</div>
					</div>
				</div>
				<DisplayOrders searchName={filter} />
			</div>
		</div>
	);
};

export default OrderHistory;
