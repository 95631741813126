import React from 'react';
import './index.css';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { Link } from 'react-router-dom';

const tabTheme = ['proposition', 'position', 'membre', 'performance'];

const backgroundTheme = [
	'linear-gradient(80.12deg, var(--color--SecondBackground) 39.24%, #483DBC 101.31%)',
	'linear-gradient(80.12deg, var(--color--SecondBackground) 39.24%, #59C5E8 101.31%)',
	'linear-gradient(80.12deg, var(--color--SecondBackground) 39.24%, #DC6969 101.31%)',
	'linear-gradient(80.12deg, var(--color--SecondBackground) 39.24%, #C7BF11 101.31%)',
];

const images = [
	require('../../../assets/top10proposition.png'),
	require('../../../assets/top10position.png'),
	require('../../../assets/top10membre.png'),
	require('../../../assets/top10performance.png'),
];

const IdeeTop: React.FC = () => {
	const queryParameters = new URLSearchParams(window.location.search);
	const theme = queryParameters.get('');
	if (!theme) return <></>;
	const realTheme = tabTheme.indexOf(theme);
	if (realTheme < 0) return <></>;
	return (
		<div id='ideeTop'>
			<div id='chevronsIdee'>
				<Link
					to='/app/idee'
					style={{
						height: '2rem',
						width: '2rem',
						background: 'var(--color--SecondBackground)',
						textDecoration: 'none',
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '100px',
						color: 'var(--color--Blanc)',
					}}
					className='hgp'
				>
					<ChevronLeftRoundedIcon style={{ width: '2rem', height: '2rem' }} />
				</Link>
				<div
					style={{
						height: '2rem',
						width: '2rem',
						background: 'var(--color--SecondBackground)',
						textDecoration: 'none',
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '100px',
						marginLeft: '1rem',
						color: 'var(--color--GrisText)',
					}}
					// className='hgp'
				>
					<ChevronRightRoundedIcon style={{ width: '2rem', height: '2rem' }} />
				</div>
			</div>
			<div id='ideeTopBody' style={{ background: backgroundTheme[realTheme] }}>
				<img src={images[realTheme]} style={{ height: '170px', width: '170px' }} alt='idee' />
			</div>
		</div>
	);
};

export default IdeeTop;
