import { squadType } from '../../../store/squad/reducer';
import { userState } from '../../../store/user/reducer';
import $axios from '../../../utils/axios';
import useConnectionStore from '../../../utils/connectionStore/connection';
import { parrainageType } from '../MembersList';

export type parrainageState = {
	tab: parrainageType[];
	current: parrainageType;
	squadName: string;
};

const SignUpSquad = async (
	parrainage: parrainageState,
	currentSquad: squadType | null,
	user: userState,
	connectionUser: any,
	connectionSquad: any,
	connectionOrder: any,
	connectionActif: any,
	addPopup: any,
) => {
	const newTab = [...parrainage.tab];
	if (
		!(
			parrainage.current.email === '' ||
			parrainage.current.message === '' ||
			parrainage.squadName === ''
		)
	)
		newTab.push(parrainage.current);
	newTab.forEach((obj) => {
		obj.squadName = parrainage.squadName;
	});
	if (currentSquad) {
		await $axios
			.post('sendEmail', {
				emails: newTab.map((message) => {
					return {
						...message,
						squadName: parrainage.squadName,
						name: user.nickname,
						token: '',
					};
				}),
			})
			.then((obj) => {
				addPopup('Invitation Envoyée', 'info');
			})
			.catch((e) => {
				addPopup('Invitation Echouée', 'success');
			});
		return;
	}
	try {
		const res = await $axios
			.post('squad/signup', {
				squadName: parrainage.squadName,
				pathPP: '0',
			})
			.then(async (res) => {
				const squadsM = res.data['squads'];
				connectionSquad(squadsM);
				connectionUser({
					email: res.data['email'],
					nickname: res.data['nickname'],
					token: res.data['token'],
					pathname: res.data['pathname'],
					id: res.data['id'],
					titre: res.data['titre'],
					objectif: res.data['objectif'],
					votes: res.data['votes'],
					shareSquad: res.data['shareSquad'],
				});
				connectionActif();
				if (res.data['squads'] && res.data['squads'].length)
					connectionOrder(
						res.data['orders'],
						res.data['squads'][0].nickname,
						res.data['percentage'],
					);
				await $axios
					.post('sendEmail', {
						emails: newTab.map((message) => {
							return {
								...message,
								squadName: parrainage.squadName,
								name: user.nickname,
								token: res.data['tokenSquad'],
							};
						}),
					})
					.then((obj) => {
						addPopup('Invitation Envoyée', 'success');
					})
					.catch((e) => {
						addPopup('Invitation Echouée', 'success');
					});
			})
			.catch((e) => {
				console.error('Fail create Squad: ', e);
			});
	} catch (e) {
		console.error(e);
	}
};

export default SignUpSquad;
