import { AlertProps } from '@mui/material';
import { createSlice, nanoid } from '@reduxjs/toolkit';

const popupDuration = 6000;

export interface popupType {
	key: string;
	mustShow: boolean;
	severity: string & AlertProps['severity'];
	message: string;
	removeAfterMs: number;
}

export interface popupState {
	tabPopup: popupType[];
}

const initialState: popupState = { tabPopup: [] };

export const popUpSlice = createSlice({
	name: 'popup',
	initialState,
	reducers: {
		addPopup(
			state,
			{ payload: { message, severity, removeAfterMs = popupDuration, key = nanoid() } },
		) {
			state.tabPopup.push({
				message: message,
				severity: severity,
				removeAfterMs: removeAfterMs,
				key: key,
				mustShow: true,
			});
		},
		removePopup(state, { payload: { key } }) {
			state.tabPopup.forEach((pp) => {
				if (pp.key === key) pp.mustShow = false;
			});
		},
	},
});

export default popUpSlice.reducer;
export const { addPopup, removePopup } = popUpSlice.actions;
