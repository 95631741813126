import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import userReducer from './user/reducer';
import squadReducer from './squad/reducer';
import orderReducer from './order/reducer';
import actifReducer from './actif/reducer';
import popupReducer from './popup/reducer';

const store = configureStore({
	reducer: {
		user: userReducer,
		squad: squadReducer,
		order: orderReducer,
		actif: actifReducer,
		popup: popupReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({}),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// https://redux.js.org/usage/usage-with-typescript#type-checking-redux-thunks
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, AnyAction>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export default store;
