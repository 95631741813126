import { NoEncryption } from '@mui/icons-material';
import { createTheme, Paper } from '@mui/material';
import { flexbox } from '@mui/system';
import { NONAME } from 'dns';
import { transform } from 'typescript';

const t0 = '#06ff70';
const t50 = '#62fs95';
const t150 = '#06FC70';
const t100 = '#58db86';
const t200 = '#2f7548';
const t300 = '#194f26';
const t400 = '#153621';

const Noir = '#000000';
const GrisNoir = '#212121';
const VertFluo = '#06FF70';
const Blanc = '#FFFFFF';
const GrisText = '#BFBFBF';
const Red = '#FF0000';
const background = '#0A0A29';

export const theme = createTheme({
	typography: {
		fontFamily: ['SF Pro Display'].join(''),
	},
	palette: {
		primary: {
			main: t0,
		},
		secondary: {
			main: t50,
		},
		background: {
			default: Noir,
		},
		common: {
			white: 'white',
			// black: Noir,
		},
		success: {
			main: t150,
		},
		warning: {
			main: GrisText,
		},
		info: {
			main: Blanc,
		},
		error: {
			main: Red,
		},
	},
	components: {
		MuiAvatar: {
			styleOverrides: {
				root: {
					height: '40px',
					width: '40px',
				}
			},
			variants: [
				{
					props: { variant: 'small' },
					style: {
						height: '25px',
						width: '25px',
					}
				},
				{
					props: { variant: 'big' },
					style: {
						height: '40px',
						width: '40px',
					}
				}
			]
		},
		MuiButton: {
			styleOverrides: {
				root: {
					color: GrisNoir,
					borderColor: t0,
					borderWidth: 2,
					background: t0,
					textTransform: 'none',
					borderRadius: '500px',
					// minBlockSize: '56px',
					// paddingInline: '48px',
					paddingInline: '24px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					fontWeight: 700,
					letterSpacing: '0.02857rem',
				},
			},
			variants: [
				{
					props: { variant: 'Blanco' },
					style: {
						color: '#FFFFFF',
						background: 'transparent',
						borderColor: '#FFFFFF',
						position: 'relative',
						zIndex: 99,
						borderRadius: '10px',
						border: '2px solid white !important',
						letterSpacing: '0.02857rem',
						'&:hover': {
							background: 'transparent',
						},
					},
				},
				{
					props: { variant: 'fullBlanco' },
					style: {
						background: 'var(--color--Blanc)',
						color: 'var(--color--Noir)',
						'&:hover': {
							background: 'var(--color--Blanc)',
						},
					},
				},
				{
					props: { variant: 'duoBlanc' },
					style: {
						background: 'var(--color--SecondBackground)',
						color: 'var(--color--GrisText)',
						'&:hover': {
							background: 'var(--color--SecondBackground)',
							color: Blanc,
						},
					},
				},
				{
					props: { variant: 'duoNoir' },
					style: {
						background: 'var(--color--Background)',
						color: 'var(--color--GrisText)',
						'&:hover': {
							background: 'var(--color--Background)',
						},
					},
				},
				{
					props: { variant: 'duoPropositionActive' },
					style: {
						background: 'var(--color--Blanc)',
						color: 'var(--color--Background)',
						border: '0px solid white',
						'&:hover': {
							background: 'var(--color--Blanc)',
						},
					},
				},
				{
					props: { variant: 'duoPropositionDesactive' },
					style: {
						background: 'transparent',
						color: 'var(--color--GrisText)',
						border: '0px solid white',
						'&:hover': {
							background: 'transparent',
							color: 'var(--color--Blanc)',
						},
					},
				},
				{
					props: { variant: 'noirWBorder' },
					style: {
						background: 'transparent',
						color: 'var(--color--SecondBackground)',
						border: '2px solid var(--color--SecondBackground)',
						borderRadius: '7px',
						fontWeight: 700,
						fontSize: '0.8rem',
						'&:hover': {
							background: 'transparent',
							color: 'var(--color--Noir)',
						},
					},
				},
			],
		},
		MuiDialog: {
			styleOverrides: {
				root: {
					// backgroundColor: '#282828',
					backgroundColor: 'var(--color--SecondBackground)',
					// backgroundColor: '#131958',
					color: Blanc,
					borderRadius: '10px',
					// boxShadow: '0 4px 4px rgb(0 0 0 / 30%)',
					boxShadow: '0px 0px 1px 0px var(--color--GrisText)',
					// boxShadow: '0px 0px 10px 0px #FFFFFF',
					// box-shadow: 0px 4px 20px -1px#FFFFFF;
					// padding: '1rem',
					display: 'flex',
					flexDirection: 'column',
					minWidth: '200px',
					minHeight: '400px',
					width: '400px',
					maxWidth: 'fit-content',
					maxHeight: 'fit-content',
					marginLeft: 'auto',
					marginRight: 'auto',
					marginTop: 'auto',
					marginBottom: 'auto',
					paddingTop: 0,
					// position: 'sticky',
				},
				paper: {
					style: {
						alignSelf: 'start',
						background: 'var(--color--Background)',
						margin: 0,
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontSize: '1rem',
					fontWeight: '700 !important',
					display: 'flex',
					justifyContent: 'space-between',
					width: '100%',
					color: 'white',
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					color: 'white',
					width: '100%',
					margin: '0.5rem 0rem',
					borderColor: 'white',
					className: 'testBorderBlanc',
					inputProps: {
						color: 'info',
						style: { color: 'white', className: 'testBorderBlanc' },
					},
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					className: 'testBorderBlanc',
					style: { color: 'white', className: 'testBorderBlanc' },
					inputProps: {
						className: 'testBorderBlanc',
						style: { color: 'white' },
					},
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					zIndex: 12000,
					backgroundColor: 'transparent',
					color: 'white',
					justifySelf: 'flex-start',
					fontFamily: 'SF Pro Display, sans-serif',
					paddingBottom: '0',
					paddingLeft: '0',
					marginBottom: '1.5rem',
					fontWeight: 700,
					// letterSpacing: '-0.05rem',
					fontSize: '1.5rem',
					paddingTop: 0,
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					// background: 'transparent',
					style: {
						margin: 0,
					},
					background: 'var(--color--Background)',
					color: Blanc,
					boxShadow: 'none !important',
					width: '100%',
					fontSize: '0.8rem',
					// marginTop: '1rem',
					// marginBottom: '1rem',
					fontWeight: '400',
					margin: 0,
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: 'white',
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					margin: 0,
					'&$selected': {
						background: Noir,
						color: 'red',
					},
					'::selection': {
						color: 'red',
					},
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					color: 'white',
				},
			},
			defaultProps: {
				style: { color: 'white' },
				inputProps: { style: { color: 'white !important' } },
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					paddingTop: 0,
					marginTop: '0',
					color: GrisText,
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					styles: {
						background: 'red',
					},
				},
			},
		},
	},
});

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		Blanco: true;
		fullBlanco: true;
		duoNoir: true;
		duoBlanc: true;
		duoPropositionActive: true;
		duoPropositionDesactive: true;
		noirWBorder: true;
	}
}

declare module '@mui/material/Avatar' {
	interface AvatarPropsVariantOverrides {
		small: true;
		big: true;
	}
}