import { createSlice } from '@reduxjs/toolkit';

export type userSquadType = {
	nickname: string;
	email: string;
	pathname?: string;
	id?: number;
	objectif?: number;
	titre?: number;
};

export type percentageType = {
	amountSquad: number;
	amountUserInvested: number;
	createdAt: string;
	id: number;
	lastAmountNewUserInvested: number;
	lastAmountSquad: 104920.09999999999;
	lastValueUserInvestment: 51710.049999999996;
	squadId: number;
	updatedAt: string;
	userId: number;
};

export type squadType = {
	id?: number;
	nickname: string;
	pathPP?: string;
	borderColor?: string;
	pastAmounts?: string;
	sockets?: string;
	date?: string;
	users: userSquadType[];
	percentages?: percentageType[];
};

export interface squadState {
	mySquads: squadType[];
	status: string;
}

const initialState: squadState = { mySquads: [], status: 'init' };

export const squadSlice = createSlice({
	name: 'squad',
	initialState,
	reducers: {
		connection(state, { payload: { tabSquads } }) {
			state.status = 'connected';
			state.mySquads = tabSquads;
		},
		disconnection(state) {
			state.status = 'disconnected';
			state.mySquads = [];
		},
	},
});

export const { connection, disconnection } = squadSlice.actions;
export default squadSlice.reducer;
