import { useCallback } from 'react';
import { AppState, useAppDispatch, useAppSelector } from '..';
import { voteType } from '../../components/Dashboard/PopUpVote';
import { connection, disconnection, connecting } from './reducer';

export type userInfos = {
	nickname: string;
	email: string;
	token: string;
	pathname: string;
	id: number;
	titre?: string;
	objectif?: string;
	votes: voteType[];
	shareSquad: string;
};

export function useConnection(): ({
	nickname,
	email,
	token,
	pathname,
	id,
	titre,
	objectif,
	votes,
}: userInfos) => void {
	const dispatch = useAppDispatch();
	return useCallback(
		({ nickname, email, token, pathname, id, titre, objectif, votes, shareSquad }: userInfos) => {
			dispatch(connection({ nickname, email, token, pathname, id, titre, objectif, votes, shareSquad }));
		},
		[dispatch],
	);
}

export function useDisconnection(): () => void {
	const dispatch = useAppDispatch();
	return useCallback(() => {
		dispatch(disconnection());
	}, [dispatch]);
}

export function useConnecting(): () => void {
	const dispatch = useAppDispatch();
	return useCallback(() => {
		dispatch(connecting());
	}, [dispatch]);
}

export function useUser(): AppState['user'] {
	return useAppSelector((state: AppState) => {
		return state.user;
	});
}
