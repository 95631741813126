import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	FormControl,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	makeStyles,
	Menu,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	TextField,
	Theme,
	useTheme,
} from '@mui/material';
import { GreyTooltip } from '../DisplayPopup/PopupItem';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import './index.css';
import actifs from '../../dbActifs/actifs.json';
import useWindowDimensions from '../../utils/windowDimensions';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useUser } from '../../store/user/hook';
import { useCurrentSquad, useSquad } from '../../store/squad/hook';
import $axios from '../../utils/axios';
import useMap from '../../utils/useMap';
import {
	useOrder,
	useOrderCash,
	useOrderPositions,
	useOrderTotalValue,
} from '../../store/order/hook';
import { useActif, useActifPriceMap } from '../../store/actif/hook';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import ActifDisplay from '../ActifDisplay';
import { useAddPopup } from '../../store/popup/hook';

type actifType = {
	name: string;
	ticker: string;
	country: string;
	url: string;
	isCrypto?: boolean;
	percentage?: number;
	price?: number;
};

type propositionType = {
	isAchat: boolean;
	termLength: string;
	price?: number | string;
	numberAction?: number | string;
};

const initActif = {
	name: '',
	ticker: '',
	country: '',
	url: '',
	isCrypto: false,
};

const initProp = {
	isAchat: true,
	termLength: 'Durée de la proposition',
	price: '',
	numberAction: '',
	percentage: 0,
};

const terms = ['Durée de la proposition', 'Court terme', 'Milieu terme ???', 'Long terme'];

const MenuProps = {
	PaperProps: {
		style: {
			display: 'flex',
			justifyContent: 'center',
			marginTop: '-0.6rem',
			width: 250,
			BackgroundColor: 'rgba(255, 255, 255)',
		},
	},
};

function colorDependValue(perc: number) {
	if (perc > 0) return 'var(--color--Vert)';
	if (perc === 0) return 'var(--color--GrisText)';
	return 'var(--color--Rouge)';
}

const Propositions: React.FC = () => {
	const [tableResearch, setTableResearch] = useState<string>('');
	const actif = useActif();
	const mapActifPrice = useActifPriceMap(actif);
	const [tabActifs, setTabActifs] = useState<actifType[]>(actif.actifs);
	const [currentActif, setCurrentActif] = useState<actifType>(initActif);
	const [currentProposition, setCurrentProposition] = useState<propositionType>(initProp);
	const order = useOrder();
	const cashDispo = useOrderCash(order);
	const squadTotalValue = useOrderTotalValue(order, actif);
	const currentSquad = useCurrentSquad();
	const window = useWindowDimensions();
	const user = useUser();
	const squads = useSquad();
	const width = window.width;
	const addPopup = useAddPopup();
	const CurrPrice = mapActifPrice.has(currentActif.name)
		? Math.round(mapActifPrice.get(currentActif.name)?.price! * 10) / 10
		: 0;
	function checkCurrentProposition(): boolean {
		if ((currentProposition.numberAction as number) < 0) return true;
		if (!currentProposition.isAchat) return false;
		if (
			cashDispo <
			(currentProposition.numberAction !== ''
				? currentProposition.price !== ''
					? (currentProposition.numberAction as number) * (currentProposition.price as number)
					: (currentProposition.numberAction as number) * CurrPrice
				: 0)
		)
			return true;
		if (currentProposition.numberAction as number) return false;
		return true;
	}

	function proposerOrder() {
		if (!currentProposition.numberAction || !currentSquad) return;
		// addPopup('Envoie de la Proposition', 'info');
		$axios
			.post('order', {
				squadName: currentSquad.nickname,
				actifQuantity:
					(currentProposition.numberAction as number) * (currentProposition.isAchat ? 1 : -1),
				price: currentProposition.price ? currentProposition.price : CurrPrice,
				actifName: currentActif.name,
			})
			.then((obj) => addPopup('Proposition validée', 'info'))
			.catch(() => addPopup('Proposition refusée', 'error'));
		setCurrentProposition(initProp);
		setCurrentActif(initActif);
	}

	useEffect(() => {
		setTabActifs(
			actif.actifs.filter(
				(obj) =>
					obj.name.toLowerCase().startsWith(tableResearch.toLowerCase()) ||
					obj.ticker.toLowerCase().startsWith(tableResearch.toLowerCase()) ||
					obj.country.toLowerCase().startsWith(tableResearch.toLowerCase()) ||
					(obj.isCrypto && 'crypto'.startsWith(tableResearch.toLocaleLowerCase())),
			),
		);
	}, [tableResearch]);

	useEffect(() => { }, [currentProposition]);

	const displayAssets = () => {
		return (
			<div id='bodyTable'>
				{tabActifs.map((actif, index) => {
					return (
						<div
							className='actifRow'
							key={`${actif.name}`}
							style={{ marginTop: index ? '1rem' : '0rem' }}
							onClick={() => setCurrentActif(actif)}
						>
							<div className='leftRow'>
								<img
									src={
										!actif.isCrypto
											? `https://s3-symbol-logo.tradingview.com/${actif.url}--big.svg`
											: `https://s3-symbol-logo.tradingview.com/crypto/${actif.url}--big.svg`
									}
									style={{
										width: '50px',
										height: '50px',
										borderRadius: '100px',
										// padding: '5px',
										// backgroundColor: 'var(--)',
										borderWidth: 0,
									}}
									alt='...'
								/>
								<div className='actifInfo'>
									<strong>
										{actif.name.charAt(0).toUpperCase() + actif.name.slice(1)}
									</strong>
									<p>{actif.ticker}</p>
								</div>
								<GreyTooltip
									title='Performance sur les
									24 dernières heures.'
									placement='bottom'
								>
									<div
										className='actifPercentage'
										style={{
											// color: mapActifsPercentage.has(actif.name)
											// ? colorDependValue(mapActifsPercentage.get(actif.name)![1])
											// : 'var(--color--GrisText)',
											color: 'var(--color--Noir)',
										}}
									>
										{mapActifPrice.has(actif.name) ? (
											mapActifPrice.get(actif.name)?.evolutionDayPercentage
										) : (
											<CircularProgress style={{ height: '0.5rem', width: '0.5rem' }} />
										)}
										<div style={{ marginLeft: '0.1rem' }}>%</div>
									</div>
								</GreyTooltip>
							</div>
							<div className='rightRow'>
								{width > 768 ? <p>Proposer au Squad</p> : ''}
								<ArrowForwardRoundedIcon
									style={{
										width: '20px',
										height: '30px',
										marginLeft: width > 768 ? '1rem' : '1rem',
									}}
									color='info'
								/>
							</div>
						</div>
					);
				})}
			</div>
		);
	};
	return (
		<div id='proposition'>
			{/* <SecondHeader /> */}
			<div id='bodyProposition'>
				<div id='headerTable'>
					<p>Assets</p>
					<FormControl sx={{ width: window.width > 768 ? 'auto' : '8rem' }} variant='outlined'>
						<InputLabel htmlFor='outlined-adornment-password' sx={{ color: 'white' }}>
							Recherche
						</InputLabel>
						<Input
							color='info'
							sx={{
								color: 'white',
								// height: '2rem',
								padding: 0,
								borderColor: 'white !important',
								borderWidth: 2,
								borderTopWidth: '1px',
							}}
							value={tableResearch}
							onChange={(value) => setTableResearch(value.target.value)}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										// onClick={handleClickShowPassword}
										// onMouseDown={handleMouseDownPassword}
										edge='end'
										color='info'
									>
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				</div>
				{displayAssets()}
			</div>
			<Dialog
				style={{ maxWidth: '300px', minHeight: '340px' }}
				open={currentActif.name !== ''}
				onClose={() => {
					setCurrentProposition(initProp);
					setCurrentActif(initActif);
				}}
				PaperProps={{
					style: {
						height: '100%',
						width: '100%',
						position: 'relative',
						margin: 0,
						padding: '2rem',
						// background: 'var(--color--Background)',
					},
				}}
			>
				<CloseRoundedIcon
					className='leaveButton'
					onClick={() => {
						setCurrentProposition(initProp);
						setCurrentActif(initActif);
					}}
				/>

				<ActifDisplay currentActif={currentActif} mapActifPrice={mapActifPrice} />
				<Box>
					<Box
						sx={{
							display: 'flex',
							width: 'calc(100% - 2px)',
							marginTop: '1.5rem',
							justifyContent: 'space-around',
							alignItems: 'center',
							border: '1px solid var(--color--Blanc)',
							position: 'relative',
							borderRadius: '7px',
							// border: '2px solid var(--color--Blanc)',
						}}
					>
						<Button
							variant={
								currentProposition.isAchat
									? 'duoPropositionActive'
									: 'duoPropositionDesactive'
							}
							style={{
								alignSelf: 'center',
								borderRadius: '5px',
								width: '50%',
								height: '1.5rem',
							}}
							// disabled={!(email && pswd)}
							onClick={() => setCurrentProposition({ ...currentProposition, isAchat: true })}
						>
							Achat
						</Button>
						<Button
							variant={
								!currentProposition.isAchat
									? 'duoPropositionActive'
									: 'duoPropositionDesactive'
							}
							style={{
								alignSelf: 'center',
								borderRadius: '5px',
								marginLeft: '0.1rem',
								width: '50%',
								height: '1.5rem',
							}}
							onClick={() =>
								setCurrentProposition({ ...currentProposition, isAchat: false })
							}
						>
							Vente
						</Button>
					</Box>
					<Box
						sx={{
							height: '10rem',
							width: '100%',
							marginTop: '1.5rem',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Box
							style={{
								width: '100%',
								display: 'flex',
								position: 'relative',
							}}
						>
							<div
								style={{
									width: '45%',
									display: 'flex',
									justifyContent: 'space-between',
									flexDirection: 'column',
									height: '1.7rem',
								}}
							>
								<div
									style={{
										color: 'var(--color--BleuFonceText)',
										fontSize: '0.6rem',
										display: 'flex',
										height: '0.6rem',
									}}
								>
									<GreyTooltip
										title="Un Ordre limite consiste à acheter ou vendre à un prix déterminé ou plus avantageux.L'exécution de l'ordre n'est pas garantie."
										placement='top'
									>
										<strong>
											PRIX{' '}
											<InfoRoundedIcon
												sx={{
													width: '0.5rem',
													height: '0.5rem',
													alignSelf: 'flex-end',
												}}
											/>
										</strong>
									</GreyTooltip>
								</div>
								<p
									style={{
										margin: 0,
										// letterSpacing: '-0.03rem'
									}}
								>
									Choisir un prix limite
								</p>
							</div>
							<div
								style={{
									display: 'flex',
									alignItems: 'end',
									height: '2rem',
									width: '50%',
									marginLeft: '1rem',
								}}
							>
								<FormControl
									sx={{
										m: 0,
										width: '100%',
										// moitie du padding que je n'arrive pas a changer du input
										marginTop: '-8.25px',
										fontWeight: 400,
										fontSize: '0.8rem',
									}}
								>
									<InputLabel
										style={{
											fontSize: '0.8rem',
											display: currentProposition.price === '' ? 'flex' : 'none',
											justifyContent: 'center',
											alignContent: 'center',
											top: '-0.8rem',
											color: 'var(--color--BleuFonceText)',
											// marginTop: '-4px',
										}}
									>
										{mapActifPrice.has(currentActif.name)
											? Math.round(mapActifPrice.get(currentActif.name)?.price! * 10) /
											10
											: 0}
									</InputLabel>
									<TextField
										value={currentProposition.price}
										InputLabelProps={{ color: 'info' }}
										sx={{ margin: 0 }}
										color='success'
										InputProps={{
											color: 'info',
											className: 'testBorderBlancSmall',
											style: {
												fontSize: '0.8rem',
												paddingRight: '0',
												paddingLeft: '0px',
											},
											inputProps: {
												style: {
													// paddingLeft: '2rem',
													textAlign: 'center',
													margin: 0,
												},
											},
											startAdornment: (
												<InputAdornment position='end'>
													<RemoveRoundedIcon
														onClick={() => {
															setCurrentProposition({
																...currentProposition,
																price:
																	currentProposition.price !== ''
																		? (currentProposition.price as number) - 1
																		: CurrPrice - 1,
															});
														}}
														color='info'
														style={{
															height: '1rem',
															width: '1rem',
															cursor: 'pointer',
														}}
													/>
												</InputAdornment>
											),
											endAdornment: (
												<InputAdornment position='start'>
													<AddRoundedIcon
														color='info'
														style={{
															height: '1rem',
															width: '1rem',
															cursor: 'pointer',
														}}
														onClick={() => {
															setCurrentProposition({
																...currentProposition,
																price:
																	currentProposition.price !== ''
																		? (currentProposition.price as number) + 1
																		: CurrPrice + 1,
															});
														}}
													/>
												</InputAdornment>
											),
										}}
										type='number'
										onChange={(value) =>
											setCurrentProposition({
												...currentProposition,
												price: value.target.value,
											})
										}
									/>
								</FormControl>
							</div>
						</Box>
						<Box
							style={{
								width: '100%',
								display: 'flex',
								position: 'relative',
								marginTop: '0.5rem',
							}}
						>
							<div
								style={{
									width: '45%',
									display: 'flex',
									justifyContent: 'space-between',
									flexDirection: 'column',
									height: '1.7rem',
								}}
							>
								<div
									style={{
										color: 'var(--color--BleuFonceText)',
										fontSize: '0.6rem',
										display: 'flex',
										height: '0.6rem',
									}}
								>
									<strong>
										NOMBRE{' '}
										{/* <InfoRoundedIcon
											sx={{ width: '0.5rem', height: '0.5rem', alignSelf: 'flex-end' }}
										/> */}
									</strong>
								</div>
								<p
									style={{
										margin: 0,
										// letterSpacing: '-0.03rem'
									}}
								>
									Choisir la quantité
								</p>
							</div>
							<div
								style={{
									display: 'flex',
									alignItems: 'end',
									height: '2rem',
									width: '50%',
									marginLeft: '1rem',
								}}
							>
								<FormControl
									sx={{
										m: 0,
										width: '100%',
										// moitie du padding que je n'arrive pas a changer du input
										marginTop: '-8.25px',
										fontWeight: 400,
										fontSize: '0.8rem',
									}}
								>
									<TextField
										value={currentProposition.numberAction}
										InputLabelProps={{ color: 'info' }}
										sx={{ margin: 0 }}
										color='success'
										InputProps={{
											color: 'info',
											className: 'testBorderBlancSmall',
											style: {
												fontSize: '0.8rem',
												paddingRight: '0',
												paddingLeft: '0px',
											},
											inputProps: {
												style: {
													// paddingLeft: '2rem',
													textAlign: 'center',
													margin: 0,
												},
											},
											startAdornment: (
												<InputAdornment position='end'>
													<RemoveRoundedIcon
														onClick={() => {
															setCurrentProposition({
																...currentProposition,
																numberAction:
																	currentProposition.numberAction === ''
																		? -1
																		: (currentProposition.numberAction as number) -
																		1,
															});
														}}
														color='info'
														style={{
															height: '1rem',
															width: '1rem',
															cursor: 'pointer',
														}}
													/>
												</InputAdornment>
											),
											endAdornment: (
												<InputAdornment position='start'>
													<AddRoundedIcon
														color='info'
														style={{
															height: '1rem',
															width: '1rem',
															cursor: 'pointer',
														}}
														onClick={() => {
															setCurrentProposition({
																...currentProposition,
																numberAction:
																	currentProposition.numberAction === ''
																		? +1
																		: (currentProposition.numberAction as number) +
																		1,
															});
														}}
													/>
												</InputAdornment>
											),
										}}
										type='number'
										onChange={(value) =>
											setCurrentProposition({
												...currentProposition,
												numberAction: value.target.value,
											})
										}
									/>
								</FormControl>
							</div>
						</Box>
						<Box
							style={{
								width: '100%',
								display: 'flex',
								position: 'relative',
								marginTop: '0.5rem',
							}}
						>
							<div
								style={{
									width: '45%',
									display: 'flex',
									justifyContent: 'space-between',
									flexDirection: 'column',
									height: '1.7rem',
								}}
							>
								<div
									style={{
										color: 'var(--color--BleuFonceText)',
										fontSize: '0.6rem',
										display: 'flex',
										height: '0.6rem',
									}}
								>
									<strong>
										POURCENTAGE{' '}
										{/* <InfoRoundedIcon
											sx={{ width: '0.5rem', height: '0.5rem', alignSelf: 'flex-end' }}
										/> */}
									</strong>
								</div>
								<p
									style={{
										margin: 0,
										// letterSpacing: '-0.03rem'
									}}
								>
									Total du squad
								</p>
							</div>
							<div
								style={{
									display: 'flex',
									alignItems: 'end',
									height: '2rem',
									width: '50%',
									marginLeft: '1rem',
								}}
							>
								<FormControl
									sx={{
										m: 0,
										width: '100%',
										// moitie du padding que je n'arrive pas a changer du input
										marginTop: '-8.25px',
										fontWeight: 400,
										fontSize: '0.8rem',
									}}
								>
									<InputLabel
										style={{
											fontSize: '0.8rem',
											display: 'flex',
											justifyContent: 'center',
											alignContent: 'center',
											top: '-0.8rem',
											color:
												cashDispo >
													(currentProposition.numberAction !== ''
														? currentProposition.price !== ''
															? (currentProposition.numberAction as number) *
															(currentProposition.price as number)
															: (currentProposition.numberAction as number) * CurrPrice
														: 0)
													? 'var(--color--Blanc)'
													: 'var(--color--Rouge)',
											// marginTop: '-4px',
										}}
									>
										{(
											Math.round(
												((currentProposition.numberAction !== ''
													? currentProposition.price !== ''
														? (currentProposition.numberAction as number) *
														(currentProposition.price as number)
														: (currentProposition.numberAction as number) * CurrPrice
													: 0) /
													squadTotalValue) *
												1000,
											) / 10
										).toLocaleString() + ' %'}
									</InputLabel>
									<TextField
										InputLabelProps={{ color: 'info' }}
										sx={{ margin: 0 }}
										color='success'
										disabled={true}
										InputProps={{
											color: 'info',
											className: 'testBorderBlancSmall',
											style: {
												fontSize: '0.8rem',
												paddingRight: '0',
												paddingLeft: '0px',
											},
											inputProps: {
												style: {
													textAlign: 'center',
													margin: 0,
												},
											},
										}}
										type='number'
									/>
								</FormControl>
							</div>
						</Box>
						<Button
							variant='fullBlanco'
							style={{ borderRadius: '7px', marginTop: '1.5rem', height: '1.7rem' }}
							disabled={checkCurrentProposition()}
							onClick={() => proposerOrder()}
						>
							Proposer
						</Button>
					</Box>
				</Box>
			</Dialog>
		</div>
	);
};

export default Propositions;
