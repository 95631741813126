import React, { useEffect, useState } from 'react';
import './index.css';
import { VictoryAnimation, VictoryLabel, VictoryPie } from 'victory';
import {
	Avatar,
	Box,
	Button,
	Dialog,
	DialogTitle,
	InputLabel,
	Paper,
	Popper,
	TextField,
} from '@mui/material';
import useWindowDimensions from '../../utils/windowDimensions';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import $axios from '../../utils/axios';
import { useConnection, useUser } from '../../store/user/hook';
import { useCurrentSquad, useSquad, useSquadConnection } from '../../store/squad/hook';
import { useNavigate } from 'react-router-dom';
import {
	useMapActifGain,
	useOrder,
	useOrderCash,
	useOrderConnection,
	useOrderInitiatedTab,
	useOrderInvested,
	useOrderInvestments,
	useOrderPositionsCleared,
	useOrderTotalGain,
	useOrderTotalValue,
} from '../../store/order/hook';
import useConnectionStore from '../../utils/connectionStore/connection';
import { useActif, useActifPriceMap } from '../../store/actif/hook';
import MembersList, { parrainageType } from './MembersList';
import SignUpSquad, { parrainageState } from './SignUpSquad';
import PopUpSignSquad, { openVoteType } from './PopUpSignSquad';
import PopUpVoteEmpty from './PopUpVoteEmpty';
import PopUpVote, { countdown, currentOrderType, voteType } from './PopUpVote';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import DisplayInvestments from './DisplayInvestments';
import DisplayBars, { colorScale } from './DisplayBars';
import PopUpProfil from './PopUpProfil';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import { useAddPopup } from '../../store/popup/hook';
import { GreyTooltip } from '../DisplayPopup/PopupItem';
import ProfilInfos from '../smallComponent/profilDashboard/profilInfos';
import ProfilDashboard from '../smallComponent/profilDashboard';
import PopUpLeave from './PopUpLeave';

export const initOpenVote: openVoteType = { openInvite: false, openVote: false, openProfil: false, openLeave: false };

export type dataVictory = {
	x: string;
	y: number;
	eventKey?: string;
};

const Dashboard: React.FC = () => {
	const user = useUser();
	const order = useOrder();
	const currentSquad = useCurrentSquad();
	const actif = useActif();
	const { connectionUser, connectionSquad, connectionOrder, connectionActif } =
		useConnectionStore();
	const [openVote, setOpenVote] = useState<openVoteType>(initOpenVote);
	const [currentOrder, setCurrentOrder] = useState<currentOrderType>({ index: 0 });
	const [timeLeft, setTimeLeft] = useState(0);
	const windowDim = useWindowDimensions();
	const navigate = useNavigate();
	const width = windowDim.width;
	const [currentFocusPie, setCurrentFocusPie] = useState<string>('usd');
	const mapInvestments = useOrderPositionsCleared(useOrder(), useActif());
	const addPopup = useAddPopup();
	const [data, setData] = useState<dataVictory[]>([]);
	const [rotatePie, setRotatePie] = useState<number>(0);
	const gainTotal = useOrderTotalGain(useOrder(), useActif());
	const initiatedOrders = useOrderInitiatedTab(useOrder(), useUser());
	const mapActifGain = useMapActifGain(useOrder());
	const tabActifInvested = actif.actifs.filter((actif) => mapInvestments.has(actif.name));
	const mapActifPrice = useActifPriceMap(useActif());
	const squadTotalValue = useOrderTotalValue(order, actif);
	const myInvestment = useOrderInvested(useOrder(), user.id);
	const cashAvailable = useOrderCash(useOrder());
	const queryParameters = new URLSearchParams(window.location.search);
	const tokenUrl = queryParameters.get('token');

	let widthVictory = 300;
	let heightVictory = 150;

	if (width < 768) {
		widthVictory = 250;
		heightVictory = 125;
	}

	useEffect(() => {
		const dataNew: dataVictory[] = [];
		let restData = 1;
		tabActifInvested.forEach((obj) => {
			if (mapInvestments.has(obj.name) && squadTotalValue) {
				const perc = mapInvestments.get(obj.name)! / squadTotalValue;
				restData -= perc;
				if (!perc) return {};
				dataNew.push({
					x: obj.name,
					y: perc,
					eventKey: obj.name,
				});
			}
			return {};
		});
		dataNew.push({ x: 'cash', y: restData, eventKey: 'cash' });
		setData(dataNew);
	}, [actif, order]);
	// }, []);
	useEffect(() => {
		if (
			!initiatedOrders.length ||
			!initiatedOrders[currentOrder.index] ||
			!initiatedOrders[currentOrder.index].timeTerm
		)
			return;
		const targetDate = new Date(initiatedOrders[currentOrder.index].timeTerm).getTime();
		const interval = setInterval(() => {
			const now = new Date().getTime();
			const timeLeft = targetDate - now;
			setTimeLeft(timeLeft);
		}, 1000);
		return () => clearInterval(interval);
	}, [initiatedOrders.length, currentOrder.index]);

	useEffect(() => {
		if (currentSquad)
			return navigate('/app/');
		
	}, [tokenUrl]);
	useEffect(() => {
		if (
			initiatedOrders.length &&
			!currentOrder.user &&
			currentSquad &&
			openVote.openVote === true
		) {
			currentSquad?.users.forEach((userSquad) => {
				if (user.id && initiatedOrders[currentOrder.index].userId === userSquad.id) {
					$axios.get(`vote/${initiatedOrders[currentOrder.index].id}`).then((obj) => {
						const votes = obj.data['votes'];
						let voted: boolean = false;
						votes.forEach((vote: voteType) => {
							if (user.id === vote.userId) voted = true;
						});
						setCurrentOrder({
							...currentOrder,
							index: currentOrder.index,
							user: userSquad,
							votes: votes,
							voted: voted,
						});
					});
				}
			});
		}
	}, [initiatedOrders.length, currentSquad, currentOrder.user, openVote.openVote]);
	function chooseWhichToOpen(isAdd: boolean, isLeave: boolean) {
		if (currentSquad) setParrainage({ ...parrainage, squadName: currentSquad.nickname });
		if (isLeave) {
			setOpenVote({ ...initOpenVote, openLeave: !openVote.openLeave });
			return;
		}
		if (isAdd) return setOpenVote({ ...initOpenVote, openInvite: !openVote.openInvite });
		setOpenVote({ ...initOpenVote, openVote: !openVote.openInvite });
	}
	function isPeepoHappy() {
		if (currentFocusPie === 'usd') {
			return gainTotal > 0;
		}
		const orderC = order.orders.find((orderT) => orderT.actif === currentFocusPie);
		if (!orderC) return false;
		return (
			mapActifGain.has(currentFocusPie) &&
			mapActifPrice.has(currentFocusPie) &&
			mapActifPrice.get(currentFocusPie)!.price > orderC.realPrice!
		);
	}
	function calculatePrice(currentFocusPie: string, restant: number) {
		let price = 0;
		let currentQuantity = 0;
		const ordersLatestToEarliest = order.orders
			.slice()
			.sort((a, b) => b.id! - a.id!)
			.filter((orderT) => orderT.actif === currentFocusPie && orderT.state === 2);
		ordersLatestToEarliest.forEach((orderT) => {
			if (currentQuantity >= restant) return;
			if (orderT.actionQuantity + currentQuantity > restant) {
				price += (restant - currentQuantity) * orderT.realPrice!;
				currentQuantity += restant - currentQuantity;
			} else {
				price += orderT.actionQuantity * orderT.realPrice!;
				currentQuantity += orderT.actionQuantity;
			}
		});
		return price;
	}
	const [parrainage, setParrainage] = useState<parrainageState>({
		tab: [],
		current: { email: '', message: '' },
		squadName: '',
	});
	// const colorScale = ['#273273', '#023059', '#131A40', '#4E64A6', '#035AA6', '#0367A6'];
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const openInviteJoin = Boolean(anchorEl);
	const handleClickPieChart = (event: any, eventKey: any) => {
		setCurrentFocusPie(eventKey.datum.x);
		// console.log(eventKey.style.fill);
	};
	const handleWhellPieChart = (event: any, eventKey: any) => {
		setRotatePie(rotatePie + event.deltaY);
	};
	if (user.status !== 'connected') return <></>;
	return (
		<div id='dashboard'>
			<div id='bodyDashboard'>
				<div id='dashboardCentrist'>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'flex-start',
							maxWidth: '100%',
							// paddingRight: '1rem',
						}}
						className='columnMobile'
					>
						<ProfilDashboard openVote={openVote} setOpenVote={setOpenVote} isHeader={false} />
						<GreyTooltip title='Contributions aux décisions du Squad' placement='top'>
							<div id='investmentBackground'>
								<strong
									style={{
										textAlign: 'end',
										alignSelf: width > 768 ? '' : 'center',
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<HowToVoteIcon
										sx={{ width: '1rem', height: '1rem', marginRight: '0.5rem' }}
									/>
									{' Participation'}
								</strong>
								<Button
									id='buttonVote'
									onClick={() => chooseWhichToOpen(!currentSquad, false)}
									sx={{ backgroundColor: 'white', position: 'relative' }}
									className='hoverWhiteButton'
								>
									{currentSquad ? 'Voter' : 'Voter'}
									{currentSquad && initiatedOrders.length > 0 ? (
										<div id='notifButtonVote'>{initiatedOrders.length}</div>
									) : (
										<></>
									)}
								</Button>
							</div>
						</GreyTooltip>
					</div>
					<div id='secondBodyDashboard'>
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<div id='investmentFirstRow'>
								<GreyTooltip title='Participation personnelle totale' placement='top'>
									<div id='investmentFirstRowFirstBlock'>
										<strong>Total Investi</strong>
										<p className='nFS'>{myInvestment.toLocaleString()} $</p>
									</div>
								</GreyTooltip>
								<div id='lineInvestRow' />
								<GreyTooltip title='Somme des valeurs des actifs du Squad' placement='top'>
									<div id='investmentFirstRowSecondBlock'>
										<strong>Valeur Totale</strong>
										<div
											id='investmentGain'
											style={{ display: 'flex', alignItems: 'flex-start' }}
										>
											{Math.floor(gainTotal).toLocaleString()} ${' '}
											<div
												style={{
													margin: 0,
													color: 'var(--color--GrisText)',
													fontSize: '0.8rem',
													alignSelf: 'center',
													marginLeft: '0.1rem',
												}}
											>
												(
												{!myInvestment
													? 0
													: Math.round((gainTotal / myInvestment) * 100)}
												%)
											</div>
										</div>
									</div>
								</GreyTooltip>
							</div>
							<div id='memberTitle'>Membres</div>
							<div
								id='memberBlock'
								style={{
									alignItems:
										currentSquad && 1 ? '' : 'flex-start',
								}}
							>
								{MembersList(currentSquad, chooseWhichToOpen, setAnchorEl, anchorEl, windowDim, setOpenVote)}
							</div>
						</div>
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-end',
							}}
							className='columnMobile'
						>
							<div id='investmentPart'>
								<div style={{ paddingTop: '1rem' }}>
									<div
										style={{
											border: '2px solid var(--color--SecondBackground)',
											width: '9.4rem',
											borderRadius: '10px',
											// padding: '2px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
											marginTop: '-4.65px',
											marginLeft: windowDim.width > 768 ? '0' : '0.5rem'
										}}
									>
										<div id='buttonObjectives'>
											<Button
												variant={'duoBlanc'}
												style={{
													alignSelf: 'center',
													width: '4.7rem',
													height: '1.6rem',
													fontSize: '0.8rem',
													borderRadius: '8px',
													padding: 0,
													letterSpacing: '-0.02rem',
												}}
											>
												Votre part
											</Button>
											<Button
												variant={'duoNoir'}
												style={{
													alignSelf: 'center',
													borderRadius: '8px',
													width: '4.7rem',
													height: '1.6rem',
													fontSize: '0.8rem',
													letterSpacing: '-0.02rem',
												}}
												onClick={() => addPopup('Pas encore Disponible', 'info')}
											>
												Objectif
											</Button>
										</div>
									</div>
									<p
										style={{ color: 'var(--color--Blanc)', fontWeight: '700', marginLeft: windowDim.width > 768 ? '0' : '1rem' }}
									// className='hgp'
									>
										<strong style={{ fontSize: '1.2rem' }}>
											{Math.round(order.percentage * 100)}
										</strong>{' '}
										%
									</p>
									<DisplayBars />
								</div>
							</div>
							<div id='investmentHeader'>Allocation des Actifs</div>
							<div id='investmentBlock' className='hgpl'>
								<div id='investmentSecondRow'>
									<div id='circleInvestment'>
										<VictoryPie
											data={data}
											// innerRadius={60}
											// radius={50}
											padAngle={({ datum }) => 1}
											labelRadius={60}
											cornerRadius={3}
											width={widthVictory}
											height={heightVictory}
											radius={({ datum }) => 45 - (datum.x === currentFocusPie ? 1 : 0)}
											innerRadius={({ datum }) =>
												60 + (datum.x === currentFocusPie ? 1 : 0)
											}
											events={[
												{
													target: 'data',
													eventHandlers: {
														onClick: handleClickPieChart,
														onMouseMove: handleClickPieChart,
													},
												},
											]}
											style={{
												labels: { fill: 'white', fontSize: 0 },
												data: {
													// cursor: 'pointer',

													cursor: 'pointer',
													transform: windowDim.width > 768 ? `translateX(75px) translateY(75px)` : '',
													position: 'relative',
												},
											}}
											colorScale={colorScale}
										/>
										<div
											style={{
												position: 'absolute',
												left: windowDim.width > 768 ? 'calc(100px - 2.5rem)' : 'auto',
												top: 'auto',
												width: '5rem',
												height: '5rem',
												textAlign: 'center',
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
												fontSize: '0.8rem',
												fontWeight: 700,
											}}
										>
											{isPeepoHappy() ? (
												<img
													src={require('../../assets/content.png')}
													style={{
														height: '1.5rem',
														width: '1.5rem',
														mixBlendMode: 'lighten',
													}}
													alt='...'
												/>
											) : (
												<img
													src={require('../../assets/pascontent.png')}
													style={{
														height: '1.5rem',
														width: '1.5rem',
														mixBlendMode: 'lighten',
													}}
													alt='...'
												/>
											)}
											<p
												style={{
													margin: 0,
													fontWeight: 400,
													fontSize: '0.8rem',
													color: 'var(--color--Blanc)',
												}}
											>
												{currentFocusPie !== 'usd' &&
													mapActifGain.has(currentFocusPie) ? (
													mapActifGain.get(currentFocusPie)?.restant + ' actions'
												) : (
													<></>
												)}
											</p>
											<p style={{ margin: 0, fontWeight: 400, fontSize: '0.7rem' }}>
												{currentFocusPie !== 'usd' &&
													mapActifGain.has(currentFocusPie) &&
													mapActifPrice.has(currentFocusPie)
													? (
														mapActifGain.get(currentFocusPie)!.restant *
														mapActifPrice.get(currentFocusPie)!.price -
														calculatePrice(
															currentFocusPie,
															mapActifGain.get(currentFocusPie)!.restant,
														)
													).toLocaleString() + '$'
													: Math.round(squadTotalValue).toLocaleString() + '$'}{' '}
											</p>
										</div>
									</div>
								</div>
								{DisplayInvestments(
									squadTotalValue,
									cashAvailable,
									mapInvestments,
									tabActifInvested,
									currentFocusPie,
									setCurrentFocusPie,
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{PopUpSignSquad(
				openVote,
				setOpenVote,
				parrainage,
				setParrainage,
				currentSquad,
				user,
				connectionUser,
				connectionSquad,
				connectionOrder,
				connectionActif,
				addPopup,
			)}
			{PopUpVoteEmpty(openVote, setOpenVote, initiatedOrders, navigate)}
			<PopUpVote
				openVote={openVote}
				setOpenVote={setOpenVote}
				initiatedOrders={initiatedOrders}
				currentOrder={currentOrder}
				setCurrentOrder={setCurrentOrder}
				timeLeft={timeLeft}
				actif={actif}
				currentSquad={currentSquad}
				squadTotalValue={squadTotalValue}
				mapActifPrice={mapActifPrice}
			/>
			<PopUpProfil openVote={openVote} setOpenVote={setOpenVote} />
			<PopUpLeave openVote={openVote} setOpenVote={setOpenVote} parrainage={parrainage} setParrainage={setParrainage} />
			<Popper open={openInviteJoin && !currentSquad} anchorEl={anchorEl}>
				<Box
					sx={{
						// border: 1,
						p: 1,
						bgcolor: 'var(--color--Blanc)',
						border: '2px solid var(--color--Noir)',
					}}
				>
					<InputLabel
						style={{
							fontSize: '0.8rem',
							fontWeight: '500',
							color: 'var(--color--Noir)',
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						Code du Squad{' '}
						<CloseRoundedIcon
							onClick={() => setAnchorEl(null)}
							style={{ cursor: 'pointer' }}
							className='hgp'
						/>
					</InputLabel>
					<TextField
						sx={{
							width: '100%',
							margin: '0.5rem 0rem',
							borderColor: 'var(--color--Noir)',
							padding: '0 !important',
						}}
						InputProps={{ color: 'info', className: 'smallInput' }}
						variant='outlined'
					/>
				</Box>
			</Popper>
		</div>
	);
};

export default Dashboard;
