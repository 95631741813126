import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useActif } from '../../store/actif/hook';
import {
	useOrder,
	useOrderCash,
	useOrderInitiatedTab,
	useOrderInvestments,
	useOrderPositions,
	useOrderPositionsCleared,
} from '../../store/order/hook';
import { useAddPopup } from '../../store/popup/hook';
import { useCurrentSquad, useSquad } from '../../store/squad/hook';
import { useUser } from '../../store/user/hook';
import { GreyTooltip } from '../DisplayPopup/PopupItem';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import './index.css';
import useWindowDimensions from '../../utils/windowDimensions';

export interface secondHeaderProps {
	isHeader?: boolean;
}

const SecondHeader = (props: secondHeaderProps) => {
	const { isHeader } = props;
	const location = useLocation();
	const path = location.pathname;
	const user = useUser();
	const currentSquad = useCurrentSquad();
	const [ppProfil, setPpProfil] = useState<number>(6);
	const cashAvailable = useOrderCash(useOrder());
	const initiatedOrders = useOrderInitiatedTab(useOrder(), useUser());
	const positions = useOrderPositionsCleared(useOrder(), useActif());
	const order = useOrder();
	const [copy, setCopy] = useState<boolean>(false);
	const window = useWindowDimensions();

	const displayCorrectInfo = () => {
		if (path === '/app/' || path === '/app/history') return displayDashboard();
		if (path === '/app/proposition' || path === '/app/idee') return displayProposition();
		return <></>;
	};
	const displayHistory = () => {
		if (isHeader) return <></>;
		return (
			<div id='squadResume'>
				<GreyTooltip
					title="Nombre d'ordre proposé au sein du Squad."
					placement='bottom'
					className='GreyTooltipStyle'
				>
					<div id='numberMember' className='hgp'>
						<strong>{order.orders ? order.orders.length : 0}</strong> Total
					</div>
				</GreyTooltip>
				<div className='squadResumeLine' />
				<GreyTooltip
					title="Nombre d'ordre ayant été proposé puis accepté au sein du Squad."
					placement='bottom'
				>
					<div id='numberProposition' className='hgp'>
						<strong>
							{order.orders
								? order.orders.filter((orderI) => orderI.state === 1 || orderI.state === 2)
									.length
								: 0}
						</strong>{' '}
						Approuvé
					</div>
				</GreyTooltip>
				<div className='squadResumeLine' />
				<GreyTooltip title="Nombre d'ordre finalisé" placement='bottom'>
					<div id='numberPosition' className='hgp'>
						<strong>
							{order.orders ? order.orders.filter((orderI) => orderI.state === 2).length : 0}
						</strong>{' '}
						Terminé
					</div>
				</GreyTooltip>
			</div>
		);
	};
	const displayDashboard = () => {
		if (isHeader) return <></>;
		return (
			<div id='squadResume'>
				<GreyTooltip
					title='Personnes dans votre Squad'
					placement='bottom'
					className='GreyTooltipStyle'
				>
					<div id='numberMember' className='hgp'>
						<strong>{currentSquad ? currentSquad.users.length : 0}</strong> Membres
					</div>
				</GreyTooltip>
				<div className='squadResumeLine' />
				<GreyTooltip title='Propositions en cours de vote' placement='bottom'>
					<div id='numberProposition' className='hgp'>
						<strong>{initiatedOrders.length}</strong> Propositions
					</div>
				</GreyTooltip>
				<div className='squadResumeLine' />
				<GreyTooltip
					title="Nombre d'actifs différents possédés par votre Squad"
					placement='bottom'
				>
					<div id='numberPosition' className='hgp'>
						<strong>{positions.size}</strong> Positions
					</div>
				</GreyTooltip>
			</div>
		);
	};
	const displayProposition = () => {
		if (isHeader) return <></>;
		return (
			<GreyTooltip title='Liquidité de votre Squad' placement='left-end'>
				<div id='squadCash'>
					<strong>Cash disponible: </strong> {Math.floor(cashAvailable).toLocaleString()}$
				</div>
			</GreyTooltip>
		);
	};

	function isSecondHeaderNeeded() {
		if (path === '/app/proposition' || path === '/app/idee') return true;
		if (path === '/app/history') return true;
		if (path === '/app/') return true;
		return false;
	}

	function share() {
		const url = 'https://www.thesquad.fr/app/login?code=' + user.shareSquad;
		navigator.clipboard.writeText(url);
		setCopy(true);
		setTimeout(() => setCopy(false), 2000);
	}

	if (user.status !== 'connected' || !isSecondHeaderNeeded()) return <></>;
	if (!isHeader && window.width < 768) return <></>;
	if (isHeader && window.width > 768) return <></>;
	return (
		<div id='secondHeader'>
			<GreyTooltip title={'Partager le lien de votre Squad'} placement='bottom'>
				<div id='partageSecondHeader' onClick={() => share()}>
					Partager{' '}
					{!copy ? (
						<ContentCopyIcon style={{ height: '1rem' }} />
					) : (
						<CheckCircleRoundedIcon style={{ height: '1rem' }} />
					)}
				</div>
			</GreyTooltip>
			<div
				id='profilPicture'
				// src={user.pp[ppProfil]}
				// alt='...'
				key='mypict2'
				onClick={() => setPpProfil((ppProfil + 1) % user.pp.length)}
				style={{ mixBlendMode: 'lighten', display: !isHeader ? 'flex' : 'none' }}
			/>
			<div id='secondHeaderInfo'>
				<div
					id='profilPicture'
					// src={user.pp[ppProfil]}
					// alt='...'
					key='mypict2'
					onClick={() => setPpProfil((ppProfil + 1) % user.pp.length)}
					style={{ mixBlendMode: 'lighten', display: isHeader ? 'flex' : 'none' }}
				/>
				<div id='squadName'>{currentSquad ? currentSquad.nickname : 'Sans Squad 😔'}</div>
				{displayCorrectInfo()}
			</div>
			{path === '/app/' || path === '/app/history' ? (
				<div id='tabVotes'>
					<Link
						to={'/app/'}
						style={{
							margin: 0,
							paddingBottom: '0.3rem',
							cursor: 'pointer',
							textDecoration: 'none',
							borderBottom: path === '/app/' ? '2px solid white' : '',
							color: path === '/app/' ? 'white' : 'var(--color--GrisText)',
						}}
					>
						Apercu
					</Link>
					<Link
						to={'/app/history'}
						style={{
							margin: 0,
							paddingBottom: '0.3rem',
							marginLeft: '1rem',
							cursor: 'pointer',
							borderBottom: path === '/app/history' ? '2px solid white' : '',
							color: path === '/app/history' ? 'white' : 'var(--color--GrisText)',
							textDecoration: 'none',
						}}
					>
						Votes
					</Link>
				</div>
			) : (
				<></>
			)}
			{path === '/app/proposition' || path === '/app/idee' ? (
				<div id='tabVotes'>
					<Link
						to={'/app/idee'}
						style={{
							margin: 0,
							paddingBottom: '0.3rem',
							cursor: 'pointer',
							textDecoration: 'none',
							borderBottom: path === '/app/idee' ? '2px solid white' : '',
							color: path === '/app/idee' ? 'white' : 'var(--color--GrisText)',
						}}
					>
						Idées💡
					</Link>
					<Link
						to={'/app/proposition'}
						style={{
							margin: 0,
							paddingBottom: '0.3rem',
							marginLeft: '1rem',
							cursor: 'pointer',
							borderBottom: path === '/app/proposition' ? '2px solid white' : '',
							color: path === '/app/proposition' ? 'white' : 'var(--color--GrisText)',
							textDecoration: 'none',
						}}
					>
						Actifs
					</Link>
				</div>
			) : (
				<></>
			)}
			{/* {path === '/app/idee' || path === '/app/proposition' ? ( */}
			{0 ? (
				<div id='chevronsSecondHeader'>
					<Link
						to='/app/idee'
						style={{
							height: '2rem',
							width: '2rem',
							background: 'var(--color--SecondBackground)',
							textDecoration: 'none',
							position: 'relative',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: '100px',
							color: 'var(--color--Blanc)',
						}}
						className='hgp'
					>
						<ChevronLeftRoundedIcon style={{ width: '2rem', height: '2rem' }} />
					</Link>
					<div
						style={{
							height: '2rem',
							width: '2rem',
							background: 'var(--color--SecondBackground)',
							textDecoration: 'none',
							position: 'relative',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: '100px',
							marginLeft: '1rem',
							color: 'var(--color--GrisText)',
						}}
					// className='hgp'
					>
						<ChevronRightRoundedIcon style={{ width: '2rem', height: '2rem' }} />
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

export default SecondHeader;
