import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import { actifType } from '../../../store/actif/reducer';

function DisplayInvestments(
	squadTotalValue: number,
	cashAvailable: number,
	mapInvestments: Map<string, number>,
	tabActifInvested: actifType[],
	currentFocusPie: string,
	setCurrentFocusPie: any,
) {
	return (
		<div id='legendPie'>
			<div id='maxLegendPie'>
				<div className='elementLegendPie' key='usd' onClick={() => setCurrentFocusPie('usd')}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							width: '70%',
						}}
					>
						<AttachMoneyRoundedIcon
							sx={{ width: '20px', height: '20px', color: 'var(--color--Vert)' }}
						/>
						<strong
							className='legendActifName'
							style={{
								color: currentFocusPie === 'usd' ? 'var(--color--Blanc)' : '',
							}}
						>
							CASH
						</strong>
					</div>
					<div style={{ display: 'flex' }}>
						<p style={{ margin: 0 }}>{Math.floor(cashAvailable).toLocaleString()}$</p>
						<p className='legendActifPerc'>
							({squadTotalValue ? Math.round((cashAvailable / squadTotalValue) * 100) : 100}
							%)
						</p>
					</div>
				</div>
				{tabActifInvested
					.sort((a, b) => mapInvestments.get(b.name)! - mapInvestments.get(a.name)!)
					.map((actifI) => {
						if (!mapInvestments.has(actifI.name)) return <></>;
						if (!mapInvestments.get(actifI.name)) return <></>;
						return (
							<div
								className='elementLegendPie'
								style={{
									background:
										currentFocusPie === actifI.name ? 'var(--color--Background)' : '',
									mixBlendMode: currentFocusPie === actifI.name ? 'exclusion' : 'normal',
									// boxShadow:
									// 	currentFocusPie === actifI.name
									// 		? '0px 0px 1px 0px var(--color--GrisText)'
									// 		: '',
								}}
								key={actifI.name}
								onClick={() => setCurrentFocusPie(actifI.name)}
							>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center',
										width: '70%',
									}}
								>
									<img
										src={
											!actifI.isCrypto
												? `https://s3-symbol-logo.tradingview.com/${actifI.url}--big.svg`
												: `https://s3-symbol-logo.tradingview.com/crypto/${actifI.url}--big.svg`
										}
										style={{
											width: '20px',
											height: '20px',
											borderRadius: '100px',
											borderWidth: 0,
										}}
										alt='...'
									/>
									<strong
										className='legendActifName'
										style={{
											color:
												currentFocusPie === actifI.name ? 'var(--color--Blanc)' : '',
										}}
									>
										{actifI.name.toUpperCase()}
									</strong>
								</div>
								<div style={{ display: 'flex' }} className='emptyClass'>
									<p style={{ margin: 0 }}>
										{Math.round(mapInvestments.get(actifI.name)!).toLocaleString()}$
									</p>
									<p className='legendActifPerc'>
										(
										{Math.round(
											(mapInvestments.get(actifI.name)! / squadTotalValue) * 100,
										)}
										%)
									</p>
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
}

export default DisplayInvestments;
