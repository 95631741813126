import { createSlice } from '@reduxjs/toolkit';
import actifDB from '../../dbActifs/actifs.json';

export type priceType = {
	price: number;
	evolutionDayPercentage: number;
	actifName: string;
};

export type actifType = {
	name: string;
	ticker: string;
	country: string;
	url: string;
	isCrypto?: boolean;
	percentage?: number;
	price?: number;
};

export type actifState = {
	actifs: actifType[];
	prices: priceType[];
	status: string;
	lastUpdate?: number;
};

const initialState: actifState = { actifs: actifDB.actifs, prices: [], status: 'init' };

export const actifSlice = createSlice({
	name: 'actif',
	initialState,
	reducers: {
		update(state, { payload: { prices, lastUpdate } }) {
			if (state.status !== 'connected')
				return console.error('Updating price while not connected');
			state.prices = prices;
			state.lastUpdate = lastUpdate;
		},
		connection(state, { payload }) {
			state.status = 'connected';
		},
		disconnection(state, { payload }) {
			state.status = 'diconnected';
		},
	},
});

export const { update, connection, disconnection } = actifSlice.actions;
export default actifSlice.reducer;
