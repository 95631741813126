import './index.css';
const { ReactComponent: TestObj } = require("../../assets/testObj.svg");

const Objectifs = () => {
	return (
		<div id="objectifsT">
			<TestObj />
		</div>
	)
};

export default Objectifs;