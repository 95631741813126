import { Box, CircularProgress } from '@mui/material';
import { actifType, priceType } from '../../store/actif/reducer';

interface ActifDisplayP {
	currentActif: actifType;
	mapActifPrice: Map<string, priceType>;
	heightWidth?: number;
}

function colorDependValue(perc: number) {
	if (perc > 0) return 'var(--color--Vert)';
	if (perc === 0) return 'var(--color--GrisText)';
	return 'var(--color--Rouge)';
}

const ActifDisplay: React.FC<ActifDisplayP> = (props) => {
	const { currentActif, mapActifPrice, heightWidth } = props;
	const CurrPrice = mapActifPrice.has(currentActif.name)
		? Math.round(mapActifPrice.get(currentActif.name)?.price! * 10) / 10
		: 0;
	const heightW = heightWidth ? heightWidth : 50;
	return (
		<Box
			style={{
				marginBottom: '0rem',
				fontSize: '1rem',
				letterSpacing: '0',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<img
				src={
					!currentActif.isCrypto
						? `https://s3-symbol-logo.tradingview.com/${currentActif.url}--big.svg`
						: `https://s3-symbol-logo.tradingview.com/crypto/${currentActif.url}--big.svg`
				}
				style={{
					width: `${heightW}px`,
					height: `${heightW}px`,
					borderRadius: '100px',
					// padding: '5px',
					// backgroundColor: 'var(--)',
					borderWidth: 0,
				}}
				alt='...'
			/>
			<Box
				sx={{
					display: 'flex',
					color: 'white',
					// width: '100%',
					marginTop: '0.5rem',
					justifyContent: 'start',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						height: '40px',
						marginLeft: '0.5rem',
						justifyContent: 'space-between',
						alignItems: 'flex-start',
						fontSize: '1rem',
						fontWeight: 700,
					}}
				>
					<strong style={{ color: 'var(--color--GrisText)', overflowY: 'scroll' }}>
						{currentActif.name.charAt(0).toUpperCase() + currentActif.name.slice(1)}{' '}
					</strong>
					<div
						style={{
							display: 'flex',
							justifyContent: 'start',
							alignItems: 'center',
						}}
					>
						<p
							style={{
								margin: '0',
								color: 'var(--color--Blanc)',
								fontSize: '1rem',
							}}
						>
							{CurrPrice}$
						</p>
						<div
							className='actifPercentage'
							style={{
								color: mapActifPrice.has(currentActif.name)
									? colorDependValue(
											mapActifPrice.get(currentActif.name)?.evolutionDayPercentage!,
									  )
									: 'var(--color--GrisText)',
								fontSize: '0.6rem',
								letterSpacing: '-0.03rem',
								height: '1rem',
								width: '2rem',
								// color: 'var(--color--Noir)',
								marginLeft: '0.25rem',
							}}
						>
							{mapActifPrice.has(currentActif.name) ? (
								mapActifPrice.get(currentActif.name)?.evolutionDayPercentage
							) : (
								<CircularProgress style={{ height: '0.5rem', width: '0.5rem' }} />
							)}
							%
						</div>
					</div>
				</div>
			</Box>
		</Box>
	);
};

export default ActifDisplay;
