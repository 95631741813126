import { Avatar } from '@mui/material';
import { useUser } from '../../../store/user/hook';
import './index.css';
import ProfilInfos from './profilInfos';
import { initOpenVote } from '../../Dashboard';
import useWindowDimensions from '../../../utils/windowDimensions';

export function stringAvatar(name: string) {
	return {
		sx: {
			bgcolor: stringToColor(name),
		},
		children: `${name.charAt(0).toUpperCase()}`,
	};
}

export function stringToColor(string: string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		// color += `00${value.toString(16)}`.slice(-2);
		color = 'var(--color--SecondBackground)';
	}
	/* eslint-enable no-bitwise */

	return color;
}

export interface profilDashboardProps {
	openVote: any;
	setOpenVote: (value: any) => void;
	isHeader: boolean;
}

const ProfilDashboard = (props: profilDashboardProps) => {
	const { openVote, setOpenVote, isHeader } = props;
	const windowDim = useWindowDimensions();
	const user = useUser();
	if (windowDim.width < 768 && !isHeader) return <></>;
	if (windowDim.width >= 768 && isHeader) return <></>;
	let avatarSize = '35px';
	if (isHeader) avatarSize = '35px';
	if (user.status !== 'connected') return <></>;
	return (
		<div className='profilDashboard'>
			<Avatar
				{...stringAvatar(user.nickname)}
				className='memberPP'
				style={{
					height: avatarSize,
					width: avatarSize,
					// width:v '100%',
					color: 'var(--color--Blanc)',
					boxShadow: '0px 0px 3px 0px var(--color--GrisText)',
					cursor: 'pointer',
				}}
				onClick={() =>
					setOpenVote({ ...initOpenVote, openProfil: !openVote.openProfil })
				}
			/>
			<div className='profilResumeDashboard'>
				{isHeader ? user.nickname : <ProfilInfos />}
			</div>
		</div>
	);
};

export default ProfilDashboard;