import React, { useEffect, useState } from 'react';
import './index.css';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupsIcon from '@mui/icons-material/Groups';
import useWindowDimensions from '../../utils/windowDimensions';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import { useUser } from '../../store/user/hook';
import { useSquad } from '../../store/squad/hook';
import Cookies from 'js-cookie';

import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import useDisconnectionStore from '../../utils/disconnectStore/disconnection';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

interface sideBarP {
	widthS?: string;
	setDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
}

function dashboardPath(path: string) {
	return path === '/' || path === '/history';
}

function documentsPath(path: string) {
	return path === '/documents';
}

function objectifsPath(path: string) {
	return path === '/objectifs';
}

function propositionPath(path: string) {
	return path === '/proposition' || path === '/idee';
}

function adminPath(path: string) {
	return path === '/app/admin';
}

const Sidebar: React.FC<sideBarP> = (props) => {
	const { width, height } = useWindowDimensions();
	const widthSideBar = props.widthS;
	const setDrawer = props.setDrawer;
	const user = useUser();
	const squads = useSquad();
	const location = useLocation();
	const path = location.pathname;
	const navigate = useNavigate();
	const { disconnectionUser, disconnectionSquad, disconnectionOrder, disconnectionActif } =
		useDisconnectionStore();
	const disconnect = () => {
		if (user.status === 'connected') {
			disconnectionUser();
			disconnectionSquad();
			disconnectionOrder();
			disconnectionActif();
			Cookies.remove('token');
			return;
		}
		navigate('/app/login');
	};
	return (
		<div
			id='sideBar'
			style={{
				width: widthSideBar ? widthSideBar : '',
				marginTop: widthSideBar ? 0 : '',
				marginBottom: widthSideBar ? 0 : '',
			}}
		>
			<div id='bar'>
				<div>
					<Link
						onClick={() => (setDrawer ? setDrawer(false) : undefined)}
						id='bar1'
						to={user.status === 'connected' ? '/app/' : path}
					>
						<img src={require('../../assets/Blanc.png')} id='logoWhite' alt='...' />
					</Link>
					<div id='bar2'>
						<ul>
							<li className={!dashboardPath(path) ? '.ifNotHere' : ''}>
								<Link
									onClick={() => (setDrawer ? setDrawer(false) : undefined)}
									to={user.status === 'connected' ? '/app/' : path}
									style={{
										height: '2rem',
										display: 'flex',
										alignItems: 'center',
										textDecoration: 'none',
										color: dashboardPath(path)
											? 'var(--color--t0)'
											: 'var(--color--GrisText)',
										backgroundColor: dashboardPath(path)
											? 'var(--color--SecondBackground)'
											: 'transparent',
										borderRadius: '10px',
									}}
								>
									{dashboardPath(path) ? (
										<GroupsIcon
											sx={{
												height: '1.5rem',
												width: '1.5rem',
												maxWidth: '100%',
												marginRight: '1rem',
												// marginBottom: `${width < 768 ? '0.3rem' : '0rem'}`,
											}}
											color={dashboardPath(path) ? 'success' : 'warning'}
										/>
									) : (
										<GroupsIcon
											sx={{
												height: '1.5rem',
												width: '1.5rem',
												maxWidth: '100%',
												marginRight: '1rem',
												// marginBottom: `${width < 768 ? '0.3rem' : '0rem'}`,
											}}
											color={dashboardPath(path) ? 'success' : 'warning'}
										/>
									)}
									{squads.mySquads.length
										? squads.mySquads[0].nickname
										: 'Créer ton Squad'}
									{user.status === 'connected' ? (
										''
									) : (
										<LockIcon
											sx={{
												height: '0.5rem',
												width: '0.5rem',
												alignSelf: 'flex-start',
												marginTop: '0.6rem',
												maxWidth: '100%',
												marginLeft: '0.2rem',
											}}
										/>
									)}
								</Link>
							</li>
							<li>
								<Link
									onClick={() => (setDrawer ? setDrawer(false) : undefined)}
									to={
										user.status === 'connected' &&
											squads.mySquads.length &&
											1
											? '/app/idee'
											: path
									}
									style={{
										height: '2rem',
										display: 'flex',
										alignItems: 'center',
										marginLeft: '0rem',
										// marginTop: `${width > 768 ? '0.5rem' : '0rem'}`,
										textDecoration: 'none',
										color: propositionPath(path)
											? 'var(--color--t0)'
											: 'var(--color--GrisText)',
										backgroundColor:
											path === '/proposition'
												? 'var(--color--SecondBackground)'
												: 'transparent',
										borderRadius: '10px',
										// fontWeight: width > 768 && path === '/proposition' ? '900' : '500',
									}}
									className={!propositionPath(path) ? '.ifNotHere' : ''}
								>
									{!propositionPath(path) ? (
										<img
											style={{
												height: '3rem',
												width: '3rem',
												maxWidth: '100%',
												marginLeft: '-14px',
												marginRight: '6px',
												// marginBottom: `${width < 768 ? '0.3rem' : '0rem'}`,
											}}
											src={require('../../assets/piechartgris.png')}
											alt='...'
										// color={path === '/proposition' ? 'success' : 'warning'}
										/>
									) : (
										<img
											style={{
												height: '3rem',
												width: '3rem',
												maxWidth: '100%',
												marginLeft: '-14px',
												marginRight: '6px',
												// marginBottom: `${width < 768 ? '0.3rem' : '0rem'}`,
											}}
											src={require('../../assets/piechartvert.png')}
											alt='...'
										// color={path === '/proposition' ? 'success' : 'warning'}
										/>
									)}
									Propositions
									{user.status === 'connected' &&
										squads.mySquads.length &&
										1 ? (
										''
									) : (
										<LockIcon
											sx={{
												height: '0.5rem',
												width: '0.5rem',
												alignSelf: 'flex-start',
												marginTop: '0.6rem',
												maxWidth: '100%',
												marginLeft: '0.2rem',
											}}
										/>
									)}
								</Link>
							</li>
							{/* <li>
								<Link
									onClick={() => (setDrawer ? setDrawer(false) : undefined)}
									to={
										user.status === 'connected' &&
											squads.mySquads.length &&
											1
											? '/app/documents'
											: path
									}
									style={{
										height: '2rem',
										display: 'flex',
										alignItems: 'center',
										marginLeft: '0rem',
										// marginTop: `${width > 768 ? '0.5rem' : '0rem'}`,
										textDecoration: 'none',
										color: propositionPath(path)
											? 'var(--color--t0)'
											: 'var(--color--GrisText)',
										backgroundColor:
											path === '/proposition'
												? 'var(--color--SecondBackground)'
												: 'transparent',
										borderRadius: '10px',
										// fontWeight: width > 768 && path === '/proposition' ? '900' : '500',
									}}
									className={!propositionPath(path) ? '.ifNotHere' : ''}
								>
									{documentsPath(path) ? (
										<DescriptionIcon
											sx={{
												height: '1.5rem',
												width: '1.5rem',
												maxWidth: '100%',
												marginRight: '1rem',
												// marginBottom: `${width < 768 ? '0.3rem' : '0rem'}`,
											}}
											color={dashboardPath(path) ? 'success' : 'warning'}
										/>
									) : (
										<DescriptionIcon
											sx={{
												height: '1.5rem',
												width: '1.5rem',
												maxWidth: '100%',
												marginRight: '1rem',
												// marginBottom: `${width < 768 ? '0.3rem' : '0rem'}`,
											}}
											color={dashboardPath(path) ? 'success' : 'warning'}
										/>
									)}
									Documents
									{user.status === 'connected' &&
										squads.mySquads.length &&
										1 ? (
										''
									) : (
										<LockIcon
											sx={{
												height: '0.5rem',
												width: '0.5rem',
												alignSelf: 'flex-start',
												marginTop: '0.6rem',
												maxWidth: '100%',
												marginLeft: '0.2rem',
											}}
										/>
									)}
								</Link>
							</li> */}
							<li>
								<Link
									onClick={() => (setDrawer ? setDrawer(false) : undefined)}
									to={
										user.status === 'connected' &&
											squads.mySquads.length &&
											1
											? '/app/objectifs'
											: path
									}
									style={{
										height: '2rem',
										display: 'flex',
										alignItems: 'center',
										marginLeft: '0rem',
										// marginTop: `${width > 768 ? '0.5rem' : '0rem'}`,
										textDecoration: 'none',
										color: propositionPath(path)
											? 'var(--color--t0)'
											: 'var(--color--GrisText)',
										backgroundColor:
											path === '/proposition'
												? 'var(--color--SecondBackground)'
												: 'transparent',
										borderRadius: '10px',
										// fontWeight: width > 768 && path === '/proposition' ? '900' : '500',
									}}
									className={!propositionPath(path) ? '.ifNotHere' : ''}
								>
									{objectifsPath(path) ? (
										<EmojiEventsIcon
											sx={{
												height: '1.5rem',
												width: '1.5rem',
												maxWidth: '100%',
												marginRight: '1rem',
												// marginBottom: `${width < 768 ? '0.3rem' : '0rem'}`,
											}}
											color={dashboardPath(path) ? 'success' : 'warning'}
										/>
									) : (
										<EmojiEventsIcon
											sx={{
												height: '1.5rem',
												width: '1.5rem',
												maxWidth: '100%',
												marginRight: '1rem',
												// marginBottom: `${width < 768 ? '0.3rem' : '0rem'}`,
											}}
											color={dashboardPath(path) ? 'success' : 'warning'}
										/>
									)}
									Objectifs
									{user.status === 'connected' &&
										squads.mySquads.length &&
										1 ? (
										''
									) : (
										<LockIcon
											sx={{
												height: '0.5rem',
												width: '0.5rem',
												alignSelf: 'flex-start',
												marginTop: '0.6rem',
												maxWidth: '100%',
												marginLeft: '0.2rem',
											}}
										/>
									)}
								</Link>
							</li>
							<li>
								<Link
									onClick={() => (setDrawer ? setDrawer(false) : undefined)}
									to={
										user.email === 'elias.saci@gmail.com' ||
											user.email === 'marcailloupa@gmail.com'
											? '/app/admin'
											: '/app/'
									}
									style={{
										height: '2rem',
										display:
											user.email === 'elias.saci@gmail.com' ||
												user.email === 'marcailloupa@gmail.com'
												? 'flex'
												: 'none',
										alignItems: 'center',
										marginLeft: '0rem',
										// marginTop: `${width > 768 ? '0.5rem' : '0rem'}`,
										textDecoration: 'none',
										color: adminPath(path)
											? 'var(--color--t0)'
											: 'var(--color--GrisText)',
										backgroundColor: adminPath(path)
											? 'var(--color--SecondBackground)'
											: 'transparent',
										borderRadius: '10px',
										// fontWeight: width > 768 && path === '/proposition' ? '900' : '500',
									}}
									className={!adminPath(path) ? '.ifNotHere' : ''}
								>
									Admin
									{user.status === 'connected' &&
										squads.mySquads.length &&
										1 ? (
										''
									) : (
										<LockIcon
											sx={{
												height: '0.5rem',
												width: '0.5rem',
												alignSelf: 'flex-start',
												marginTop: '0.6rem',
												maxWidth: '100%',
												marginLeft: '0.2rem',
											}}
										/>
									)}
								</Link>
							</li>
						</ul>
					</div>
				</div>
				<div
					id='sidebarDisconnect'
					className='hgp'
					onClick={() => disconnect()}
					style={{ display: user.status === 'connected' ? '' : 'none' }}
				>
					<LogoutRoundedIcon
						style={{ height: '1.5rem', marginLeft: '24px', marginRight: '14px' }}
					/>
					<p style={{ display: 'inline', margin: 0 }}>Se déconnecter</p>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
