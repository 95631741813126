import { useEffect } from "react";
import { useOrder } from "../../../store/order/hook";
import { useCurrentSquad } from "../../../store/squad/hook";
import { userSquadType } from "../../../store/squad/reducer";
import { useUser } from "../../../store/user/hook";
import useMap from "../../../utils/useMap";
import "./index.css";

export const TableauQP = () => {
	const currentSquad = useCurrentSquad();
	const user = useUser();
	const order = useOrder();
	const [mapUserIdToUser, setMapUserIdToUser] = useMap<number, userSquadType>();
	const [mapMonthToVersement, setMapMonthToVersement] = useMap<number, number>();

	const tabPercentages =
		currentSquad && currentSquad.percentages
			? currentSquad.percentages
				.filter((per) => per.userId !== user.id)
				.sort((a, b) => a.amountUserInvested - b.amountUserInvested)
				.map((per) => {
					return {
						perc: per.lastValueUserInvestment / per.lastAmountSquad,
						userId: per.userId,
					};
				})
			: [];
	tabPercentages.unshift({ perc: order.percentage, userId: user.id });
	useEffect(() => {
		if (!currentSquad) return;
		currentSquad.users.forEach((userS) => {
			if (!userS.id) return;
			if (mapUserIdToUser.has(userS.id)) return;
			setMapUserIdToUser.set(userS.id, userS);
		});
	}, [currentSquad]);

	useEffect(() => {
		if (!currentSquad) return;
	}, [currentSquad, order]);
	return <table className="tableau">
		<thead>
			<tr>
				<th>Data</th>
				{tabPercentages.map(membre =>
					<th key={membre.userId}>
						Membre {mapUserIdToUser.get(membre.userId)?.nickname}
						<div className="subhead">
							<span>V</span>
							<span>Q.P</span>
						</div>
					</th>
				)}
				<th>Totaux
					<div className="subhead">
						<span>V</span>
						<span>Q.P</span>
					</div>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>Janvier</td>
				{tabPercentages.map(membre =>
					<td key={membre.userId}>
						<div className="subhead">
							<span>5</span>
							<span>10</span>
						</div>
					</td>
				)}
				<td>
					<div className="subhead">
						<span>20</span> {/* Total pour V */}
						<span>40</span> {/* Total pour Q.P */}
					</div>
				</td>
			</tr>
		</tbody>
	</table>
}