import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	Pagination,
	Stack,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';
import { openVoteType } from '../PopUpSignSquad';
import { orderSlice, orderState, orderType } from '../../../store/order/reducer';
import userType from '../../../utils/types/userTypes';
import { squadState, squadType, userSquadType } from '../../../store/squad/reducer';
import SellRoundedIcon from '@mui/icons-material/SellRounded';
import { actifState, priceType } from '../../../store/actif/reducer';
import ActifDisplay from '../../ActifDisplay';
import './index.css';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import $axios from '../../../utils/axios';
import { initOpenVote } from '..';
import { addPopup } from '../../../store/popup/reducer';
import React, { useEffect, useState } from 'react';
import { useAddPopup } from '../../../store/popup/hook';
import { GreyTooltip } from '../../DisplayPopup/PopupItem';

export enum valueVoteEnum {
	No = 0,
	Yes = 1,
}

export type voteType = {
	valueVote: valueVoteEnum;
	userId?: number;
	orderId?: number;
};

export type currentOrderType = {
	index: number;
	user?: userSquadType;
	votes?: voteType[];
	voted?: boolean;
};

const tabAction = ['de vendre', "d'acheter", 'de deposer', 'de retirer'];

export function countdown(s: number) {
	const d = Math.floor(s / (3600 * 24));

	s -= d * 3600 * 24;

	const h = Math.floor(s / 3600);

	s -= h * 3600;

	const m = Math.floor(s / 60);

	s -= m * 60;

	const tmp = [];

	d && tmp.push(d + 'd');

	(d || h) && tmp.push(h + 'h');

	(d || h || m) && tmp.push(m + 'm');

	tmp.push(s + 's');

	return tmp.join(' ');
}

export async function sendVote(
	valueVote: number,
	orderId: number,
	currentOrder: currentOrderType,
	setCurrentOrder: any,
	addPopup: any,
) {
	setCurrentOrder({ ...currentOrder, voted: true, user: undefined });
	await $axios
		.put('vote', { valueVote: valueVote, orderId: orderId })
		.then((obj) => {
			addPopup('Vote Envoyé', 'info');
		})
		.catch((e) => {
			addPopup('Erreur dans le Vote', 'error');
		});
}

interface PopUpVoteP {
	openVote: openVoteType;
	setOpenVote: any;
	initiatedOrders: orderType[];
	currentOrder: currentOrderType;
	setCurrentOrder: any;
	timeLeft: number;
	actif: actifState;
	currentSquad: squadType | null;
	squadTotalValue: number;
	mapActifPrice: Map<string, priceType>;
}

const PopUpVote: React.FC<PopUpVoteP> = (props) => {
	const {
		openVote,
		setOpenVote,
		initiatedOrders,
		currentOrder,
		setCurrentOrder,
		timeLeft,
		actif,
		currentSquad,
		squadTotalValue,
		mapActifPrice,
	} = props;
	const [customPercent, setCustomPercent] = useState<number>(0);
	const addPopup = useAddPopup();

	useEffect(() => {
		setCustomPercent(0);
		const interval = setInterval(() => {
			setCustomPercent((oldPerc) => {
				if (oldPerc === 100) return oldPerc;
				return oldPerc + 1;
			});
		}, 10);
		return () => clearInterval(interval);
	}, [currentOrder.index, openVote.openVote]);

	if (!initiatedOrders || !initiatedOrders[currentOrder.index]) return <></>;
	const currentActif = actif.actifs.find(
		(actifE) => actifE.name === initiatedOrders[currentOrder.index].actif,
	);
	if (!currentActif) return <></>;
	const currentOrderInfo = initiatedOrders[currentOrder.index];
	const percentOfTotal = Math.round(
		((currentOrderInfo.price * currentOrderInfo.actionQuantity) / squadTotalValue) * 100,
	);

	return (
		<Dialog
			style={{ maxWidth: '360px', minWidth: '360px', minHeight: '200px' }}
			open={openVote.openVote && !!initiatedOrders.length}
			onClose={() => setOpenVote(initOpenVote)}
			PaperProps={{
				style: {
					height: '100%',
					width: '100%',
					position: 'relative',
					margin: 0,
					padding: '2rem',
					// background: 'var(--color--Background)',
				},
			}}
		>
			<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
				<div style={{ display: 'flex', alignSelf: 'flex-start', lineHeight: '1.5rem' }}>
					<div
						style={{
							height: '2rem',
							width: '2rem',
							background: 'var(--color--SecondBackground)',
							position: 'relative',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: '100px',
						}}
						className='hgp'
					>
						<ChevronLeftRoundedIcon
							sx={{
								height: '2rem',
								width: '2rem',
								color:
									currentOrder.index <= 0
										? 'var(--color--GrisText)'
										: 'var(--color--Blanc)',
							}}
							// className='hgp'
							onClick={() =>
								currentOrder.index <= 0
									? undefined
									: setCurrentOrder({
											index: currentOrder.index - 1,
											user: null,
											votes: null,
									  })
							}
						/>
					</div>
					<div
						style={{
							height: '2rem',
							width: '2rem',
							background: 'var(--color--SecondBackground)',
							position: 'relative',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: '100px',
							marginLeft: '0.5rem',
						}}
						className='hgp'
					>
						<ChevronRightRoundedIcon
							sx={{
								height: '2rem',
								width: '2rem',
								color:
									currentOrder.index >= initiatedOrders.length - 1
										? 'var(--color--GrisText)'
										: 'var(--color--Blanc)',
							}}
							onClick={() =>
								currentOrder.index >= initiatedOrders.length - 1
									? undefined
									: setCurrentOrder({
											index: currentOrder.index + 1,
											user: null,
											votes: null,
									  })
							}
						/>
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						height: '45px',
						width: '45px',
						alignItems: 'center',
						justifyContent: 'flex-start',
						marginLeft: '1rem',
					}}
				>
					<ActifDisplay
						currentActif={currentActif}
						mapActifPrice={mapActifPrice}
						heightWidth={45}
					/>
				</div>
			</div>
			<CloseRoundedIcon className='leaveButton' onClick={() => setOpenVote(initOpenVote)} />
			<Box
				style={{
					width: '100%',
					display: 'flex',
					position: 'relative',
					marginTop: '1rem',
				}}
			>
				<div
					style={{
						width: '50%',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						height: '1.7rem',
					}}
				>
					<div
						style={{
							color: 'var(--color--BleuFonceText)',
							fontSize: '0.6rem',
							display: 'flex',
							height: '0.6rem',
						}}
					>
						<strong>DESCRIPTION </strong>
					</div>
					<p
						style={{
							margin: 0,
							// letterSpacing: '-0.03rem'
						}}
					>
						{currentOrderInfo.actionType ? 'Achat ' : 'Vente '} de{' '}
						{currentOrderInfo.actionQuantity} action(s){' '}
						{currentOrderInfo.actif.charAt(0).toUpperCase() +
							currentOrderInfo.actif.slice(1).toLowerCase()}
					</p>
				</div>
				<div
					style={{
						width: '50%',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						height: '1.7rem',
						marginLeft: '1rem',
					}}
				>
					<div
						style={{
							color: 'var(--color--BleuFonceText)',
							fontSize: '0.6rem',
							display: 'flex',
							height: '0.6rem',
						}}
					>
						<strong>CRÉÉ PAR </strong>
					</div>
					<p
						style={{
							margin: 0,
							// letterSpacing: '-0.03rem',
							display: 'flex',
							alignItems: 'end',
						}}
					>
						<PersonRoundedIcon
							style={{
								height: '1rem',
								marginRight: '0.1rem',
								color: 'var(--color--BleuText)',
							}}
						/>
						{currentOrder.user?.nickname}
					</p>
				</div>
			</Box>
			<Box
				style={{
					width: '100%',
					display: 'flex',
					position: 'relative',
					marginTop: '1rem',
				}}
			>
				<div
					style={{
						width: '50%',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						height: '1.7rem',
					}}
				>
					<div
						style={{
							color: 'var(--color--BleuFonceText)',
							fontSize: '0.6rem',
							display: 'flex',
							height: '0.6rem',
						}}
					>
						<strong>MONTANT </strong>
					</div>
					<p
						style={{
							margin: 0,
							// letterSpacing: '-0.03rem'
						}}
					>
						{percentOfTotal}% total du squad
					</p>
				</div>
				<div
					style={{
						width: '50%',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						height: '1.7rem',
						marginLeft: '1rem',
					}}
				>
					<div
						style={{
							color: 'var(--color--BleuFonceText)',
							fontSize: '0.6rem',
							display: 'flex',
							height: '0.6rem',
						}}
					>
						<strong>PRIX </strong>
					</div>
					<p
						style={{
							margin: 0,
							// letterSpacing: '-0.03rem',
							display: 'flex',
							alignItems: 'end',
						}}
					>
						<SellRoundedIcon
							style={{
								height: '1rem',
								marginRight: '0.1rem',
								color: 'var(--color--BleuText)',
							}}
						/>
						{currentOrderInfo.price}$
					</p>
				</div>
			</Box>
			<Box
				style={{
					width: '100%',
					display: 'flex',
					position: 'relative',
					marginTop: '1rem',
				}}
			>
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						height: '1.7rem',
					}}
				>
					<div
						style={{
							color: 'var(--color--BleuFonceText)',
							fontSize: '0.6rem',
							display: 'flex',
							height: '0.6rem',
						}}
					>
						<strong>VOTE EN COURS</strong>
					</div>
					<GreyTooltip
						title={`${
							currentOrder.votes
								? currentOrder.votes.filter((vote) => vote.valueVote).length
								: 0
						} / ${currentSquad ? currentSquad.users.length : '?'}`}
						placement='bottom'
					>
						<div id='timeVote'>
							<div
								id='timeBarVote'
								style={{
									backgroundImage: `linear-gradient(to right, white ${
										(currentOrder.votes && currentSquad && currentSquad.users.length
											? currentOrder.votes.length / currentSquad.users.length
											: 0) * customPercent
									}%, transparent  ${
										(currentOrder.votes && currentSquad && currentSquad.users.length
											? currentOrder.votes.length / currentSquad.users.length
											: 0) * customPercent
									}%)`,
								}}
							>
								<div
									style={{
										height: '1rem',
										border: '1px white solid',
										position: 'absolute',
										left: `${
											(currentSquad && currentSquad.users.length
												? Math.ceil(currentSquad.users.length / 2) /
												  currentSquad.users.length
												: 1) * 100
										}%`,
										top: 'calc(1rem - 1px)',
									}}
								/>
							</div>
						</div>
					</GreyTooltip>
				</div>
			</Box>
			<Box
				style={{
					width: '100%',
					display: 'flex',
					position: 'relative',
					marginTop: '1rem',
				}}
			>
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						height: '1.7rem',
					}}
				>
					<div
						style={{
							color: 'var(--color--BleuFonceText)',
							fontSize: '0.6rem',
							display: 'flex',
							height: '0.6rem',
						}}
					>
						<strong>TEMPS RESTANT</strong>
					</div>
					<p
						style={{
							margin: 0,
							marginTop: '0.5rem',
							// letterSpacing: '-0.03rem',
							display: 'flex',
							alignItems: 'flex-end',
							marginLeft: '-0.25rem',
						}}
					>
						<QueryBuilderRoundedIcon
							style={{
								height: '1rem',
								marginRight: '0.1rem',
								color: 'var(--color--BleuText)',
							}}
						/>
						{countdown(Math.round(timeLeft / 1000))}
					</p>
				</div>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					marginTop: '2rem',
				}}
			>
				<Button
					style={{
						borderRadius: '10px',
						backgroundColor: 'white',
						fontWeight: 700,
						fontSize: '1rem',
						width: '45%',
					}}
					className='hoverGreenButton'
					onClick={() => {
						sendVote(1, currentOrderInfo.id!, currentOrder, setCurrentOrder, addPopup);
					}}
					disabled={currentOrder.voted === undefined || currentOrder.voted}
				>
					Oui
				</Button>
				<Button
					style={{
						borderRadius: '10px',
						backgroundColor: 'white',
						fontWeight: 700,
						fontSize: '1rem',
						width: '45%',
					}}
					className='hoverGreenButton'
					onClick={() =>
						sendVote(0, currentOrderInfo.id!, currentOrder, setCurrentOrder, addPopup)
					}
					disabled={currentOrder.voted === undefined || currentOrder.voted}
				>
					Non
				</Button>
			</Box>
		</Dialog>
	);
};

export default PopUpVote;
