import { Stack } from '@mui/material';
import React from 'react';
import { useActivePopup } from '../../store/popup/hook';
import { PopupItem } from './PopupItem';

export const Popups: React.FC = () => {
	const popupList = useActivePopup();

	return (
		<Stack
			sx={{ position: 'absolute', top: 'calc(1rem + 3rem - 24px)', right: 'calc(3rem)' }}
			spacing={2}
		>
			{popupList.map((item) => (
				<PopupItem
					key={item.key}
					message={item.message}
					severity={item.severity}
					popKey={item.key}
					removeAfterMs={item.removeAfterMs}
				/>
			))}
		</Stack>
	);
};

export default Popups;
