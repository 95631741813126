import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	Input,
	InputAdornment,
	InputLabel,
	Paper,
	SxProps,
	TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useWindowDimensions from '../../utils/windowDimensions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './index.css';
import $axios, { setAxiosToken } from '../../utils/axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useConnecting, useUser } from '../../store/user/hook';
import { useSquad } from '../../store/squad/hook';
import useConnectionStore from '../../utils/connectionStore/connection';
import useDisconnectionStore from '../../utils/disconnectStore/disconnection';

type popupsLogin = {
	signup: boolean;
	signin: boolean;
};

const Login: React.FC = () => {
	const { width, height } = useWindowDimensions();
	const [popups, setPopups] = useState<popupsLogin>({ signup: false, signin: false });
	const [email, setEmail] = useState<string>('');
	const [nickname, setNickName] = useState<string>('');
	const [pswd, setPswd] = useState<string>('');
	const [isHidden, setIsHidden] = useState<boolean>(true);
	const [errorSign, setErrorSign] = useState<string>('');
	const [haveSquad, setHaveSquad] = useState<string>('');
	const user = useUser();

	const { connectionUser, connectionSquad, connectionOrder, connectionActif } =
		useConnectionStore();

	const { disconnectionUser, disconnectionSquad, disconnectionOrder, disconnectionActif } =
		useDisconnectionStore();

	const connectingUser = useConnecting();
	const navigate = useNavigate();
	const queryParameters = new URLSearchParams(window.location.search);
	const tokenUrl = queryParameters.get('token');
	const codeUrl = queryParameters.get('code');
	// console.log(tokenUrl, ' est le token de la squad');

	const sendSignUpSquad = async () => {
		// connectingUser();
		console.log('sendSignUpSquad:', haveSquad);
		$axios
			.post('user/signin', {
				email: email,
				pswd: pswd,
			})
			.then((me) => {
				connectionUser({
					email: me.data['email'],
					nickname: me.data['nickname'],
					token: me.data['token'],
					pathname: me.data['pathname'],
					id: me.data['id'],
					titre: me.data['titre'],
					objectif: me.data['objectif'],
					votes: me.data['votes'],
					shareSquad: me.data['shareSquad'],
				});

				connectionSquad(me.data['squads']);
				connectionActif();
				if (me.data['squads'] && me.data['squads'].length)
					connectionOrder(
						me.data['orders'],
						me.data['squads'][0].nickname,
						me.data['percentage'],
					);
				setAxiosToken(JSON.stringify({ token: me.data['token'] }));
				Cookies.set(
					'token',
					JSON.stringify({
						token: me.data['token'],
					}),
				);
				if (tokenUrl || codeUrl) {
					$axios.put('squad/signin', { squadName: haveSquad }).then((obj) => {
						connectionUser({
							email: obj.data['email'],
							nickname: obj.data['nickname'],
							token: obj.data['token'],
							pathname: obj.data['pathname'],
							id: me.data['id'],
							titre: me.data['titre'],
							objectif: me.data['objectif'],
							votes: me.data['votes'],
							shareSquad: me.data['shareSquad'],
						});
						connectionSquad(obj.data['squads']);
						if (obj.data['squads'] && obj.data['squads'].length)
							connectionOrder(
								obj.data['orders'],
								obj.data['squads'][0].nickname,
								obj.data['percentage'],
							);
					});
				}
				navigate('/app/');
			})
			.catch((err) => {
				disconnectionUser();
				disconnectionSquad();
				disconnectionOrder();
				disconnectionActif();
				console.error('Error SignIn: ', err.response.data.message);
				setErrorSign('La connexion a échoué !');
			});
	}

	const sendSignUp = async () => {
		connectingUser();
		await $axios
			.put('user/signup', {
				email: email,
				pswd: pswd,
				nickname: nickname,
			})
			.then(async (me) => {
				connectionUser({
					email: me.data['email'],
					nickname: me.data['nickname'],
					token: me.data['token'],
					pathname: me.data['pathname'],
					id: me.data['id'],
					titre: me.data['titre'],
					objectif: me.data['objectif'],
					votes: me.data['votes'],
					shareSquad: me.data['shareSquad'],
				});
				connectionSquad(me.data['squads']);
				connectionActif();
				setAxiosToken(JSON.stringify({ token: me.data['token'] }));
				Cookies.set(
					'token',
					JSON.stringify({
						token: me.data['token'],
					}),
				);
				const res = await $axios.post('sendEmail/signup');
				if (tokenUrl || codeUrl) {
					$axios.put('squad/signin', { squadName: haveSquad }).then((obj) => {
						connectionUser({
							email: obj.data['email'],
							nickname: obj.data['nickname'],
							token: obj.data['token'],
							pathname: obj.data['pathname'],
							id: me.data['id'],
							titre: me.data['titre'],
							objectif: me.data['objectif'],
							votes: me.data['votes'],
							shareSquad: me.data['shareSquad'],
						});
						connectionSquad(obj.data['squads']);
						if (obj.data['squads'] && obj.data['squads'].length)
							connectionOrder(
								obj.data['orders'],
								obj.data['squads'][0].nickname,
								obj.data['percentage'],
							);
					});
				}
				navigate('/app/');
			})
			.catch((err) => {
				if (err.response.data.message === 'Email already known and correct Infos!') {
					sendSignUpSquad();
				}
				disconnectionUser();
				disconnectionSquad();
				disconnectionOrder();
				disconnectionActif();
				// console.error('Error SignUp: ', err);
				setErrorSign('Inscription a échoué !');
				if (err.response.data.message === 'Email already known!')
					setErrorSign('Cette adresse e-mail est déjà utilisée');
			});
	};
	const sendSignIn = async () => {
		connectingUser();
		$axios
			.post('user/signin', {
				email: email,
				pswd: pswd,
			})
			.then((me) => {
				connectionUser({
					email: me.data['email'],
					nickname: me.data['nickname'],
					token: me.data['token'],
					pathname: me.data['pathname'],
					id: me.data['id'],
					titre: me.data['titre'],
					objectif: me.data['objectif'],
					votes: me.data['votes'],
					shareSquad: me.data['shareSquad'],
				});

				connectionSquad(me.data['squads']);
				connectionActif();
				if (me.data['squads'] && me.data['squads'].length)
					connectionOrder(
						me.data['orders'],
						me.data['squads'][0].nickname,
						me.data['percentage'],
					);
				setAxiosToken(JSON.stringify({ token: me.data['token'] }));
				Cookies.set(
					'token',
					JSON.stringify({
						token: me.data['token'],
					}),
				);
				navigate('/app/');
			})
			.catch((err) => {
				disconnectionUser();
				disconnectionSquad();
				disconnectionOrder();
				disconnectionActif();
				console.error('Error SignIn: ', err.response.data.message);
				setErrorSign('La connexion a échoué !');
			});
	};
	const ifEnter = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (e.key !== 'Enter') return;
		if (popups.signup && !nickname) return;
		if (!email || !pswd) return;
		if (popups.signup) sendSignUp();
		else sendSignIn();
	};

	useEffect(() => {
		if (user.status === 'connected') {
			if (tokenUrl)
				navigate('/app/?token=' + tokenUrl);
			else
				navigate('/app/');
		}
	}, [user.status]);
	useEffect(() => {
		if (user.status !== 'connected' && tokenUrl) {
			$axios.post('squad/getSquad', { token: tokenUrl }).then((obj) => {
				const squadToken = obj.data;
				setHaveSquad(squadToken.squad.nickname);
				setEmail(squadToken.email);
				setPopups({ signup: true, signin: false });
			});
		} else if (user.status !== 'connected' && codeUrl) {
			$axios.post('squad/getUserSquad', { token: codeUrl }).then((obj) => {
				const userToken = obj.data;
				setHaveSquad(userToken.squad.nickname);
				setPopups({ signup: true, signin: false });
			});
		}
	}, [tokenUrl]);
	return (
		<div id='login'>
			<div id='centerLogin' style={{ marginBottom: `${width > 768 ? '0' : '70px'}` }}>
				<img src={require('../../assets/firstLogo.png')} alt='...' id='loginLogo' />
				<p>Start your squad journey by connecting to your account</p>
				<div id='buttonsLogin'>
					<Button
						sx={{ color: '#FFFFFF', background: 'transparent', borderColor: '#FFFFFF' }}
						variant='outlined'
						onClick={() => setPopups({ signin: false, signup: true })}
					>
						Inscription
					</Button>
					<Button
						style={{ marginLeft: '1rem' }}
						onClick={() => setPopups({ signin: true, signup: false })}
					>
						Connexion
					</Button>
				</div>
			</div>
			<Dialog
				open={popups.signup}
				onClose={() => setPopups({ ...popups, signup: false })}
				sx={{
					position: 'absolute',
					marginLeft: 'auto',
					// marginLeft: `${width > 768 ? '50%' : 'auto'}`,
					marginRight: 'auto',
					marginTop: 'auto',
					marginBottom: 'auto',
					left: 0,
					top: 0,
					minHeight: '450px',
					// zIndex: 99,
					// padding
				}}
				PaperProps={{
					sx: {
						alignSelf: 'start',
						margin: 0,
						padding: '2rem',
						minHeight: '500px',
					},
				}}
			>
				<Box
					sx={{
						// backgroundColor: 'var(--color--Noir)',
						color: 'white',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'flex-start',
						alignSelf: 'start',
					}}
				>
					<DialogTitle>
						Bonjour 👋
						<div className='sousTitre'>
							Remplis le formulaire pour finaliser l’inscription {haveSquad ? 'au squad: ' : ''} {haveSquad}
						</div>
					</DialogTitle>
					{errorSign !== '' ? <Paper style={{ color: 'red' }}>{errorSign}</Paper> : ''}
					<Box
						sx={{
							display: 'flex',
							alignItems: 'flex-start',
							color: 'white',
							width: '100%',
							flexDirection: 'column',
							marginTop: '0.5rem',
						}}
					>
						<InputLabel>Email</InputLabel>
						<TextField
							type='email'
							value={email}
							InputLabelProps={{ color: 'info' }}
							InputProps={{ color: 'info', className: 'testBorderBlanc' }}
							onChange={(value) => setEmail(value.target.value)}
							color='info'
						/>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'flex-start',
							color: 'white',
							width: '100%',
							flexDirection: 'column',
							marginTop: '0.5rem',
						}}
					>
						<InputLabel>Mot de passe</InputLabel>
						<div style={{ display: 'flex', minHeight: '3rem', width: '100%' }}>
							<VisibilityIcon
								onClick={() => setIsHidden(!isHidden)}
								sx={{
									color: 'white',
									my: 'calc(1rem - 4px)',
									position: 'absolute',
									right: '2.5rem',
									zIndex: 999,
								}}
								color={isHidden ? 'info' : undefined}
								style={{ cursor: 'pointer' }}
							/>
							<TextField
								type={`${isHidden ? 'password' : 'text'}`}
								value={pswd}
								InputLabelProps={{ color: 'info' }}
								InputProps={{ color: 'info', className: 'testBorderLight' }}
								onChange={(value) => setPswd(value.target.value)}
								color='info'
							/>
						</div>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'flex-start',
							color: 'white',
							width: '100%',
							flexDirection: 'column',
							marginTop: '0.5rem',
						}}
					>
						<InputLabel>Nom</InputLabel>
						<TextField
							value={nickname}
							InputLabelProps={{ color: 'info' }}
							InputProps={{ color: 'info', className: 'testBorderBlanc' }}
							onChange={(value) => setNickName(value.target.value)}
							color='info'
						/>
					</Box>
					<Button
						style={{ alignSelf: 'center', marginTop: '1rem' }}
						disabled={!(email && nickname && pswd)}
						onClick={() => sendSignUp()}
					>
						Confirm
					</Button>
				</Box>
			</Dialog>
			<Dialog
				style={{ minHeight: '350px' }}
				open={popups.signin}
				onClose={() => setPopups({ ...popups, signin: false })}
				PaperProps={{
					sx: {
						alignSelf: 'start',
						margin: 0,
						padding: '2rem',
					},
				}}
			>
				<DialogTitle>Bonjour 👋</DialogTitle>
				{errorSign !== '' ? <Paper style={{ color: 'red' }}>{errorSign}</Paper> : ''}
				<Box
					sx={{
						display: 'flex',
						alignItems: 'flex-start',
						color: 'white',
						width: '100%',
						flexDirection: 'column',
						marginTop: '0.5rem',
					}}
				>
					<InputLabel>Email</InputLabel>
					<TextField
						type='email'
						value={email}
						InputLabelProps={{ color: 'info' }}
						InputProps={{ color: 'info', className: 'testBorderBlanc' }}
						onChange={(value) => setEmail(value.target.value)}
						color='info'
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'flex-start',
						color: 'white',
						width: '100%',
						flexDirection: 'column',
						marginTop: '0.5rem',
					}}
				>
					<InputLabel>Mot de passe</InputLabel>
					<div style={{ display: 'flex', minHeight: '3rem', width: '100%' }}>
						<VisibilityIcon
							onClick={() => setIsHidden(!isHidden)}
							sx={{
								color: 'white',
								my: 'calc(1rem - 4px)',
								position: 'absolute',
								right: '2.5rem',
								zIndex: 999,
							}}
							color={isHidden ? 'info' : undefined}
							style={{ cursor: 'pointer' }}
						/>
						<TextField
							// className='fillWidth'
							type={`${isHidden ? 'password' : 'text'}`}
							value={pswd}
							InputLabelProps={{ color: 'info' }}
							InputProps={{ color: 'info', className: 'testBorderLight' }}
							onChange={(value) => setPswd(value.target.value)}
							color='info'
						/>
					</div>
				</Box>
				<Button
					style={{ alignSelf: 'center', marginTop: '1rem' }}
					disabled={!(email && pswd)}
					onClick={() => sendSignIn()}
				>
					Confirm
				</Button>
			</Dialog>
		</div>
	);
};

export default Login;
